import { date_format, ensure_https } from "@src/helpers/functions";
import { BROKEN_IMAGE } from "@src/helpers/image_constants";
import { ROUTES } from "@src/route/my_routes";
import { Button, Col, Row, Typography } from "antd";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { LuEye } from "react-icons/lu";
import { useNavigate } from "react-router-dom";

const FactCheckPartyWiseFirstColumnMobileView = (props) => {
  const { list, type } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const image_url = ensure_https(list?.thumbnail_img);

  const handle_redirect = () => {
    if (type === "article") {
      const url = `${ROUTES.SINGLE_ARTICLE}/${list?.perma_link}.html`;
      window.open(url, "_blank");
      // navigate(`${ROUTES.SINGLE_ARTICLE}/${list?.perma_link}.html`);
    } else if (type === "fact_check") {
      const url = `${ROUTES.SINGLE_FACT_CHECK}/${list?.perma_link}.html`;
      window.open(url, "_blank");
      // navigate(`${ROUTES.SINGLE_FACT_CHECK}/${list?.perma_link}.html`);
    }
  };

  return (
    // <Row style={{ width: "100%" }}>
    <Row className="inner_row_content" onClick={handle_redirect}>
      <img
        alt={list?.title || "YouTurn Fact Check"}
        className="exposing_fake_news_first_column_image"
        src={image_url || BROKEN_IMAGE}
      />
      <Col className="exposing_fake_news_first_column_details_mobile_view">
        <Row className="exposing_fake_news_first_column_details_row">
          <Typography className="exposing_fake_news_first_column_title">
            {list?.title}
          </Typography>
        </Row>
        <Row className="exposing_fake_news_first_column_details_wrapper">
          <Col className="exposing_fake_news_first_column_view_count">
            <Typography className="exposing_fake_news_first_column_writer_name_and_views">
              {list?.views}
            </Typography>
            <LuEye className="exposing_fake_news_first_column_view_icon" />
          </Col>
          <Col className="exposing_fake_news_first_column_writer_name_and_views">{`${
            list?.staff_profile?.name || list?.user_profile?.name
          } | ${
            list?.published_date_time
              ? date_format(list?.published_date_time)
              : date_format(list?.created_date_time)
          }`}</Col>
          <Col>
            <Button className="exposing_fake_news_first_column_button">
              {t("fact_check_button_text")}
            </Button>
          </Col>
        </Row>
      </Col>
      {/* </Row> */}
    </Row>
  );
};

export default FactCheckPartyWiseFirstColumnMobileView;
