import {
  REPORT_ICON_WHITE,
  SAVE_ICON_FILLED,
  SAVE_WHITE_OUTLINED,
  SHARE_ICON,
} from "@src/helpers/image_constants";
import {
  dynamic_clear,
  dynamic_set,
  useDynamicSelector,
} from "@src/services/redux";
import React, { useEffect, useState } from "react";
import SingleFactCheckWriterDetails from "./single_fact_check_writer_details";
import { Popover, Tooltip } from "antd";
import { get_share_content } from "@src/helpers/functions";
import { SITE_URL } from "@src/helpers/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { retrieve_item } from "@src/helpers/cryptos";
import toast from "react-hot-toast";
import { ROUTES } from "@src/route/my_routes";
import { useDispatch } from "react-redux";

const SingleFactCheckFirstColumn = (props) => {
  const { handle_save } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const user_id = retrieve_item("user_id");
  let { name } = retrieve_item("language");
  const [show_bookmark_popup, set_show_bookmark_popup] = useState(false);
  const [show_report_popup, set_show_report_popup] = useState(false);
  const [show_copy_link_popup, set_copy_link_popup] = useState(false);
  const [is_copy_link, set_is_copy_link] = useState(false);
  const [share_url, set_share_url] = useState();
  const fact_check = useDynamicSelector("get_fact_check");
  const { is_saved: bookmark_saved } = useDynamicSelector("get_bookmark");
  const { status: create_report_status } =
    useDynamicSelector("report_mutation");
  const { dark_mode } = useDynamicSelector("dark_mode");

  // useEffect(() => {
  //   if (is_copy_link) {
  //     set_copy_link_popup(true);
  //     setTimeout(() => {
  //       set_copy_link_popup(false);
  //       set_is_copy_link(false);
  //     }, 500);
  //   }
  // }, [is_copy_link]);

  // useEffect(() => {
  //   if (bookmark_saved) {
  //     set_show_bookmark_popup(true);
  //     setTimeout(() => {
  //       set_show_bookmark_popup(false);
  //     }, 5000);
  //   }
  // }, [bookmark_saved]);

  useEffect(() => {
    if (create_report_status === "Success") {
      dispatch(dynamic_clear("report_mutation"));
      set_show_report_popup(true);
      setTimeout(() => {
        set_show_report_popup(false);
      }, 5000);
    }
  }, [create_report_status]);

  const handle_report_click = () => {
    if (user_id) {
      dispatch(dynamic_set("report_modal_state", { is_report_modal: true }));
    } else {
      const queryParams = new URLSearchParams();
      queryParams.set("type", "report");
      queryParams.set("types", "fact-check");
      queryParams.set("redirecturl", window.location.pathname);
      navigate({
        pathname: "/login",
        search: `?${queryParams.toString()}`,
      });
    }
  };

  const copy_to_clipboard = () => {
    const redirect_url = window.location.href;
    navigator.clipboard?.writeText(redirect_url);
    set_is_copy_link(true);
  };

  // useEffect(() => {
  //   if (name === "tamil") {
  //     const site_url = new URL(SITE_URL);
  //     const domain = site_url.hostname;
  //     const link_without_html = pathname?.replace(".html", "");
  //     const url = `https://share.${domain}${link_without_html}`;
  //     set_share_url(url);
  //   } else {
  //     const site_url = new URL(SITE_URL);
  //     const domain = site_url.hostname;
  //     const link_without_html = pathname?.replace(".html", "");
  //     const url = `https://share.${domain}/en${link_without_html}`;
  //     set_share_url(url);
  //   }
  // }, [name]);

  const share_content = get_share_content(
    fact_check?.title,
    share_url,
    fact_check?.thumbnail_img,
    copy_to_clipboard,
    show_copy_link_popup
  );

  return (
    <div className="single_article_first_column_container">
      <div className="single_article_title_report_share_save_container">
        <text
          className={
            name === "tamil"
              ? `${
                  dark_mode ? "white_text" : ""
                } single_article_first_column_title_ta`
              : `${
                  dark_mode ? "white_text" : ""
                } single_article_first_column_title`
          }
        >
          {fact_check.title}
        </text>
        <div className="single_article_top_icons_container">
          <Tooltip
            title={"Report Submitted"}
            id="primary-card-tooltip"
            open={show_report_popup}
          >
            <img
              alt={"report_icon"}
              className="single_article_top_icons"
              src={REPORT_ICON_WHITE}
              onClick={handle_report_click}
            />
          </Tooltip>
          <Popover placement="top" content={share_content} trigger="hover">
            <img
              alt={"share_icon"}
              className="single_article_top_icons"
              src={SHARE_ICON}
            />
          </Popover>

          {bookmark_saved ? (
            <Tooltip
              title={"Bookmark Saved"}
              id="primary-card-tooltip"
              open={show_bookmark_popup}
              onOpen={() => set_show_bookmark_popup(true)}
              onClose={() => set_show_bookmark_popup(false)}
            >
              <img
                alt={"save_icon"}
                className="single_article_top_icons"
                src={SAVE_ICON_FILLED}
                onClick={handle_save}
                onMouseEnter={() => set_show_bookmark_popup(true)}
                onMouseLeave={() => set_show_bookmark_popup(false)}
              />
            </Tooltip>
          ) : (
            <img
              alt={"save_icon"}
              className="single_article_top_icons"
              src={SAVE_WHITE_OUTLINED}
              onClick={handle_save}
            />
          )}
        </div>
      </div>
      <div className="single_article_writer_details">
        <SingleFactCheckWriterDetails item={fact_check} />
      </div>
    </div>
  );
};

export default SingleFactCheckFirstColumn;
