import { retrieve_item } from "@src/helpers/cryptos";
import { ensure_https } from "@src/helpers/functions";
import { USER_PROFILE } from "@src/helpers/image_constants";
import { useDynamicSelector } from "@src/services/redux";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const TopContributors = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { items: top_contributor_list, loading: top_contributor_list_loading } =
    useDynamicSelector("get_top_contributor_list");
  const language = retrieve_item("language");
  const handle_redirect_to_writer_profile = (contributor) => {
    const queryParams = new URLSearchParams();

    if (contributor?.user_profile_id) {
      queryParams.set("user_profile", contributor?.user_profile_id);
      queryParams.set("user_name", contributor?.name);
    }
    queryParams.set("type", "article");
    navigate({
      pathname: `/writer-profile/${contributor?.name}`,
      search: `?${queryParams.toString()}`,
    });
  };

  return (
    <div className="top_contributor_main_container">
      <h1
        className={
          language.name ? "top_contribute_title_ta" : "top_contribute_title"
        }
      >
        {t("top_contributors")}
      </h1>
      <div className="top_contributor_container">
        {top_contributor_list?.slice(0, 5)?.map((contributor) => {
          const image_url = ensure_https(contributor?.profile_pic);
          return (
            <img
              alt={"YouTurn Contributors"}
              onClick={() => handle_redirect_to_writer_profile(contributor)}
              src={image_url || USER_PROFILE}
              className="top_contributor_profile"
            />
          );
        })}
      </div>
    </div>
  );
};

export default TopContributors;
