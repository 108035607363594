import {
  date_format,
  ensure_https,
  format_views_count,
  get_reading_time,
  split_title,
} from "@src/helpers/functions";
import { useDynamicSelector } from "@src/services/redux";
import { Button, Skeleton } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { AiFillClockCircle } from "react-icons/ai";
import { LuEye } from "react-icons/lu";
import Carousel from "react-multi-carousel";
import ExposingFakeNewsButtonMobileView from "../home_fact_check/home_fact_check_mobile_view.jsx/exposing_fake_news_button_mobile_view";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "@src/route/my_routes";
import { BROKEN_IMAGE } from "@src/helpers/image_constants";
import { retrieve_item } from "@src/helpers/cryptos";
import TopContributors from "./top_contributors";
import { readingTime } from "reading-time-estimator";

const HomeUserContributionMobileView = (props) => {
  const { title } = props;
  const { t } = useTranslation();
  const language = retrieve_item("language");
  const {
    items: user_contribution_list,
    loading: user_contribution_list_loading,
  } = useDynamicSelector("get_user_contribution_list");
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const UserContributionMobileCard = (props) => {
    const {
      thumbnail_img,
      published_date_time,
      title,
      user_profile,
      staff_profile,
      reading_time,
      views,
      record_type,
      perma_link,
      content,
    } = props;

    const parser = new DOMParser();
    const doc = parser.parseFromString(content, "text/html");
    const result = readingTime(
      doc?.body?.textContent,
      language?.name === "tamil" ? 20 : 100
    );

    const handle_redirect = () => {
      if (record_type === "article") {
        const url = `${ROUTES.SINGLE_ARTICLE}/${perma_link}.html`;
        window.open(url, "_blank");
        // navigate(`${ROUTES.SINGLE_ARTICLE}/${perma_link}.html`);
      } else {
        const url = `${ROUTES.SINGLE_FACT_CHECK}/${perma_link}.html`;
        window.open(url, "_blank");
        // navigate(`${ROUTES.SINGLE_FACT_CHECK}/${perma_link}.html`);
      }
    };
    const image_url = ensure_https(thumbnail_img);

    return (
      <div
        className="user_contribution_mobile_card_container border-shadow"
        onClick={handle_redirect}
      >
        <img
          alt={user_profile?.name || staff_profile?.name || "YouTurn"}
          src={image_url || BROKEN_IMAGE}
          className="user_contribution_mobile_card_image"
        />
        <div className="display-flex flex-column user_contributed_story_container_footer home_user_contribute_container user_contribution_mobile_card_footer">
          <div className="display-flex flex-row justify-content-between">
            <h6 className="contribute_user_name">
              {user_profile?.name || staff_profile?.name}
            </h6>
            <div className="display-flex flex-row g-1  align-center home_user_contribute_footer">
              <LuEye color="red" className="view-count-icon" />
              <text className="view-count-tag">
                {format_views_count(views)}
              </text>
            </div>
          </div>
          <div className="display-flex flex-row justify-content-between">
            <h6 className="user_contribute_publish_time">
              {date_format(published_date_time)}
            </h6>
            <div className="display-flex flex-row g-1 align-center home_user_contribute_footer">
              <AiFillClockCircle color="gray" className="reading-time-icon" />
              <text className="time-tag">
                {get_reading_time(reading_time, t)}
              </text>
            </div>
          </div>
        </div>
        <h3 className="home_user_contribute_mobile_title">
          {split_title(title)}
        </h3>
      </div>
    );
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 800, min: 550 },
      items: 2,
    },
    // small_tablet: {
    //   breakpoint: { max: 650, min: 550 },
    //   items: 1.4,
    // },
    mobile: {
      breakpoint: { max: 550, min: 0 },
      items: 1.2,
    },
  };
  const handle_navigate = () => {
    navigate(ROUTES.USER_CONTRIBUTION);
  };
  return (
    <div className="user_contribution_mobile_main_container">
      <div className="user_contribution_mobile_title_and_top_contributors">
        {title === "recently_added" ? (
          ""
        ) : (
          <>
            <h1
              className={
                language?.name === "tamil"
                  ? "user_contribution_title_ta"
                  : "home_user_contribution_mobile_text"
              }
            >
              {t("user_contributed_stories")}
            </h1>
            <TopContributors />
          </>
        )}
      </div>

      {user_contribution_list_loading ? (
        <div className="user_contribution_mobile_card_container">
          <Skeleton active paragraph={{ rows: 10 }} title={false} />
        </div>
      ) : (
        user_contribution_list?.length > 0 && (
          <Carousel responsive={responsive} arrows={false}>
            {user_contribution_list?.slice(1, 6)?.map((contribution) => {
              return (
                <div style={{ padding: "20px" }}>
                  <UserContributionMobileCard {...contribution} />
                </div>
              );
            })}
          </Carousel>
        )
      )}
      {!user_contribution_list_loading && pathname !== "/user-contribution" && (
        <div className="exposing_fake_news_button_mobile_container">
          <Button
            className="exposing_fake_news_button_mobile"
            onClick={handle_navigate}
          >
            {t("see_all")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default HomeUserContributionMobileView;
