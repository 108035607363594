import {
  amountFormatWithSymbol,
  date_format,
  get_status_tag,
} from "@src/helpers/functions";
import { useAPIRequest } from "@src/helpers/hooks";
import { NO_CLAIM_DATA_IMAGE } from "@src/helpers/image_constants";
import { ROUTES } from "@src/route/my_routes";
import { get_claim_list_query } from "@src/services/graphql/graphql_user_profile";
import { useDynamicSelector } from "@src/services/redux";
import { Skeleton } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const UserClaimCard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const get_claims_request = useAPIRequest(
    "get_claim_list",
    get_claim_list_query
  );
  const {
    items: claims_list,
    pagination: claim_pagination,
    loading: claim_loading,
  } = useDynamicSelector("get_claim_list");
  const { dark_mode } = useDynamicSelector("dark_mode");

  const get_all_my_claim = () => {
    get_claims_request({}, true);
  };
  useEffect(() => {
    get_all_my_claim();
  }, []);
  const handle_redirect_donations = () => {
    navigate(ROUTES.USER_CLAIMS);
  };
  return (
    <div
      className={`donations-card ${dark_mode ? "dark_mode_card" : ""} `}
      id="claims"
    >
      <div className={`donations-header ${dark_mode ? "dark_mode_card" : ""}`}>
        <h6
          className={`user_contribute_table_title_text ${
            dark_mode ? "lite_mode" : "black_text"
          }`}
        >
          {t("claims")}
        </h6>
        {claims_list?.length > 0 && (
          <h6 id="view-all" onClick={handle_redirect_donations}>
            {t("view_all")}
          </h6>
        )}
      </div>
      {claims_list?.length > 0 && (
        <div className="donations-list">
          {claim_loading
            ? Array.from({ length: 3 }, (_, index) => (
                <div className="donation-item">
                  <Skeleton active paragraph={{ rows: 1 }} title={false} />
                </div>
              ))
            : claims_list?.slice(0, 5)?.map((claim, index) => {
                return (
                  <div key={index} className="donation-item">
                    <span>{date_format(claim?.created_date_time)}</span>
                    <span>{get_status_tag(claim?.status)}</span>
                  </div>
                );
              })}
        </div>
      )}
      {!claims_list?.length > 0 && !claim_loading && (
        <div className="empty_container_card">
          <img
            alt={"no_claim_image"}
            width={90}
            src={NO_CLAIM_DATA_IMAGE}
            style={{ objectFit: "cover" }}
          />
        </div>
      )}
    </div>
  );
};

export default UserClaimCard;
