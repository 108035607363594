import { date_format, ensure_https, split_title } from "@src/helpers/functions";
import { BROKEN_IMAGE } from "@src/helpers/image_constants";
import { ROUTES } from "@src/route/my_routes";
import { useDynamicSelector } from "@src/services/redux";
import { Col, Row } from "antd";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";

const ExposingFakeNewsSecondColumnMobileView = (props) => {
  const { list } = props;
  const navigate = useNavigate();
  const { items: fact_check_list, loading: fact_check_list_loading } =
    useDynamicSelector("get_fact_check_list");
  const formatted_date = date_format(list?.published_date_time);

  const image_url = ensure_https(list?.thumbnail_img);

  const navigate_to_details_page = () => {
    const url = `${ROUTES.SINGLE_FACT_CHECK}/${list?.perma_link}.html`;
    window.open(url, "_blank");
    // navigate(`${ROUTES.SINGLE_FACT_CHECK}/${list?.perma_link}.html`);
  };

  return (
    <Row
      className="exposing_fake_news_mobile_view_container"
      onClick={navigate_to_details_page}
    >
      <Col className="exposing_fake_news_mobile_view_first_col">
        <img
          className="exposing_fake_news_mobile_view_first_col_img"
          src={image_url || BROKEN_IMAGE}
          alt={list?.title || "YouTurn Fact Check"}
        />
      </Col>
      <Col className="exposing_fake_news_mobile_view_second_col">
        <text className="exposing_fake_news_mobile_view_title">
          {split_title(list?.title)}
        </text>
        <text className="exposing_fake_news_mobile_view_author_name">{`${
          list?.staff_profile?.name ||
          list?.user_profile?.name ||
          "youturn Editorial"
        } | ${formatted_date}`}</text>
      </Col>
    </Row>
  );
};

export default ExposingFakeNewsSecondColumnMobileView;
