import {
  ensure_https,
  split_tamil_english_words,
} from "@src/helpers/functions";
import { BROKEN_IMAGE, STAR_IMAGE } from "@src/helpers/image_constants";
import React, { useEffect } from "react";

const HomeAwardsCard = (props) => {
  const { award } = props;

  const handle_award = () => {
    window.open(award?.perma_link, "_blank");
  };
  const image_url = ensure_https(award?.thumbnail_img);

  return (
    <>
      <div className="home_awards_card_image_div" onClick={handle_award}>
        <img
          alt={award?.title || "YouTurn awards"}
          src={image_url || BROKEN_IMAGE}
          className="home_awards_card_image"
        />
        <div className="home_awards_card_image_overlay_content">
          <img
            alt={"star_image"}
            className="award_card_content_image"
            visible={false}
            preview={false}
            src={STAR_IMAGE}
          />
          <h6
            className="home_awards_card_image_overlay_text"
            style={{ color: "black" }}
          >
            {split_tamil_english_words(award?.title, "", 5)}
          </h6>
        </div>
      </div>
    </>
  );
};

export default HomeAwardsCard;
