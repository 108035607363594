import React from "react";
import { USER_PROFILE } from "@src/helpers/image_constants";
import { useTranslation } from "react-i18next";
import { FaPen } from "react-icons/fa";
import { LuEye } from "react-icons/lu";
import { AiFillClockCircle } from "react-icons/ai";
import {
  date_format,
  ensure_https,
  format_view_count,
} from "@src/helpers/functions";
import { useNavigate } from "react-router-dom";

const TopContributersOverlayContent = ({ item, index }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const image_url = ensure_https(item.profile_pic);

  const get_user_profile = () => {
    const queryParams = new URLSearchParams();

    if (item?.user_profile_id) {
      queryParams.set("user_profile", item?.user_profile_id);
    }
    queryParams.set("type", "article");
    navigate({
      pathname: `/writer-profile/${item?.name}`,
      search: `?${queryParams.toString()}`,
    });
  };

  return (
    <div
      key={index}
      className="top_contributors_image_container"
      onClick={get_user_profile}
    >
      <img
        alt={item?.name || "YouTurn Top Contributors"}
        src={image_url || USER_PROFILE}
        // src={USER_PROFILE}
        className="top_contributors_image"
      />
      <div className="overlay_content">
        <span className="overlay_contributor_name">{item?.name}</span>
        <div className="overlay_content_container">
          <span className="overlay_user_details_icon">
            <FaPen size={10} />
          </span>
          <span className="overlay_user_details_label">{t("works")}</span>
          <span className="overlay_user_details">{item?.total_work}</span>
        </div>
        <div className="overlay_content_container">
          <span className="overlay_user_details_icon">
            <LuEye size={10} />
          </span>
          <span className="overlay_user_details_label">{t("views")}</span>
          <span className="overlay_user_details">
            {format_view_count(item?.total_views)}
          </span>
        </div>
        <div className="overlay_content_container">
          <span className="overlay_user_details_icon">
            <AiFillClockCircle size={10} />
          </span>
          <span className="overlay_user_details_label">{t("joined")}</span>
          <span className="overlay_user_details">
            {date_format(item?.joined_date)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default TopContributersOverlayContent;
