import React, { useEffect, useState } from "react";
import { USER_PROFILE } from "@src/helpers/image_constants";
import { dynamic_clear, useDynamicSelector } from "@src/services/redux";
import {
  Button,
  Form,
  Modal,
  Popconfirm,
  Skeleton,
  Typography,
  Upload,
} from "antd";
import { RiDeleteBin5Fill } from "react-icons/ri";
import {
  UserAboutTextarea,
  UserAddressTextarea,
  UserMobileNumberInput,
  UserNameInput,
  UserProfileUpload,
} from "./user_form_inputs";
import { useTranslation } from "react-i18next";
import { IoCall } from "react-icons/io5";
import { TbMailFilled } from "react-icons/tb";
import {
  ensure_https,
  split_tamil_english_words,
} from "@src/helpers/functions";
import {
  get_user_query,
  mutation_delete_my_profile_pic,
  mutation_update_user_profile,
  mutation_update_user_profile_pic,
} from "@src/services/graphql/graphql_user_profile";
import { useAPIRequest } from "@src/helpers/hooks";
import { retrieve_item } from "@src/helpers/cryptos";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import UserProfileUpdate from "./user_profile_update";
import { IoMdClose } from "react-icons/io";
const UserProfileInformation = () => {
  const [is_editable, set_is_editable] = useState(false);
  const [is_deletable, set_is_deletable] = useState(false);
  const { user_profile, loading: get_one_loading } =
    useDynamicSelector("get_user");
  const { dark_mode } = useDynamicSelector("dark_mode");
  const user_id = retrieve_item("user_id");
  const [form] = Form.useForm();
  let get_user = useAPIRequest("get_user", get_user_query);
  const profile_pic = Form.useWatch("profile", form);
  const update_user_profile_request = useAPIRequest(
    "update_user",
    mutation_update_user_profile
  );
  const update_user_profile_update = useAPIRequest(
    "user_profile_pic_update",
    mutation_update_user_profile_pic
  );
  const deleteUserProfilePicRequest = useAPIRequest(
    "delete_my_profile_pic",
    mutation_delete_my_profile_pic
  );
  const { status: update_status, error: update_error } =
    useDynamicSelector("update_user");
  const { status: update_profile_pic_status, error: update_profile_pic_error } =
    useDynamicSelector("user_profile_pic_update");
  const { status: delete_profile_pic_status } = useDynamicSelector(
    "delete_my_profile_pic"
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handle_finish = (value) => {
    update_user_profile(value);
  };
  const handle_open = () => {
    set_is_editable(true);
  };
  const handle_close = () => {
    set_is_editable(false);
  };
  const get_user_details = (id) => {
    get_user(
      {
        id,
      },
      true
    );
  };
  const update_user_profile = (val) => {
    update_user_profile_request(
      {
        data: {
          name: val?.name,
          about: val?.about,
          mobile: val?.mobile?.toString(),
          profile_pic: profile_pic?.file?.url,
        },
      },
      true
    );
  };
  useEffect(() => {
    form.setFieldsValue({
      ...user_profile,
      profile: user_profile?.profile_pic
        ? [
            {
              name: "view",
              uid: 1,
              url: user_profile?.profile_pic,
              status: "done",
            },
          ]
        : [],
    });
  }, [user_profile]);

  const handle_remove_profile = () => {
    set_is_deletable(false);
    deleteUserProfilePicRequest({}, true);
    get_user_details(user_id);
  };
  useEffect(() => {
    if (profile_pic?.file?.url) {
      update_user_profile_update(
        {
          data: {
            profile_pic: profile_pic?.file?.url,
          },
        },
        true
      );
    }
  }, [profile_pic?.file?.url]);

  useEffect(() => {
    if (update_status === "Success") {
      // toast.success(t("Profile updated successfully"))
      handle_close();
      get_user_details(user_id);
      dispatch(dynamic_clear("update_user"));
      dispatch(dynamic_clear("delete_my_profile_pic"));
    } else if (delete_profile_pic_status === "Success") {
      get_user_details(user_id);
      // toast.success(t("profile_removed_successfully"))
      dispatch(dynamic_clear("update_user"));
    } else if (update_error) {
      dispatch(dynamic_clear("update_user"));
      dispatch(dynamic_clear("delete_my_profile_pic"));
    } else if (update_profile_pic_status === "Success") {
      // toast.success(t("profile_update_successfully"))
      get_user_details(user_id);
      dispatch(dynamic_clear("user_profile_pic_update"));
    }
  }, [
    update_status,
    update_error,
    delete_profile_pic_status,
    update_profile_pic_status,
  ]);

  const UserProfileLoading = () => {
    return (
      <div
        className="profile-card"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
          gap: "10px",
        }}
      >
        <Skeleton.Avatar active size={90} />
        {Array.from({ length: 3 }, (_, index) => (
          <Skeleton key={index} active paragraph={{ rows: 4 }} title={false} />
        ))}
      </div>
    );
  };
  const handle_delete = () => {
    set_is_deletable(true);
  };

  const handle_delete_modal = () => {
    set_is_deletable(false);
  };

  return (
    <div className="user_profile_details_main_container">
      {get_one_loading ? (
        <UserProfileLoading />
      ) : (
        <div className={`profile-card ${dark_mode ? "dark_mode_card" : ""}`}>
          <Form form={form}>
            <div className="profile-header">
              <div className="profile_header_box">
                <h5 className="profile_header">{t("profile_title")}</h5>
              </div>
              <div className="user_profile_image_container">
                <img
                  alt={user_profile?.name}
                  src={
                    profile_pic?.file?.url ||
                    user_profile?.profile_pic ||
                    USER_PROFILE
                  }
                  className="profile-img"
                />
                <div className="user_profile_update_icons_container">
                  <UserProfileUpload />
                  {user_profile?.profile_pic || profile_pic?.file?.url ? (
                    <RiDeleteBin5Fill
                      id="delete_icon"
                      className={dark_mode ? "light_mode_text" : ""}
                      size={20}
                      onClick={handle_delete}
                    />
                  ) : null}
                </div>
              </div>
            </div>
            <div className="profile_body_container">
              <div
                className={`profile-body ${
                  dark_mode ? "light_mode_text" : ""
                } `}
              >
                <h5>{user_profile?.name}</h5>
                {/* <p className="member-status">Member</p> */}
                <div className="about-me">
                  <h6>{t("about")}</h6>
                  <p>{split_tamil_english_words(user_profile?.about, 0, 30)}</p>
                </div>
                <div className="contact-info">
                  <div className="user_mobile_number_container">
                    <p>
                      <IoCall />
                    </p>
                    <p>{user_profile?.mobile}</p>
                  </div>
                  <p>
                    <TbMailFilled /> {user_profile?.email}{" "}
                  </p>
                </div>
              </div>
              <div className="profile-footer">
                <Button
                  className="edit-button"
                  onClick={handle_open}
                  htmlType="button"
                >
                  {t("update")}
                </Button>
              </div>
            </div>
          </Form>
        </div>
      )}
      <Modal
        open={is_editable}
        footer={null}
        onCancel={handle_close}
        className="report_modal"
        closeIcon={<IoMdClose className="report_modal_close_icon" />}
        destroyOnClose={true}
        centered
      >
        <UserProfileUpdate
          onFinish={handle_finish}
          form={form}
          onCancel={handle_close}
        />
      </Modal>
      <Modal
        open={is_deletable}
        footer={null}
        onCancel={handle_delete_modal}
        className="report_modal"
        closeIcon={<IoMdClose className="report_modal_close_icon" />}
        destroyOnClose={true}
        centered
      >
        {/* <UserProfileUpdate onFinish={handle_finish} form={form} onCancel={handle_close} /> */}
        <div className="report_modal_title_container">
          <text className="report_modal_title"> {t("confirmation")}</text>
        </div>
        <div className="delete_body_container">
          <text>{t("delete_profile_confirmation")}</text>
          <div className="report_modal_footer">
            <Button
              onClick={handle_delete_modal}
              className="report_cancel_button"
            >
              {t("cancel")}
            </Button>
            <Button
              type="primary"
              className="report_modal_submit_button"
              onClick={handle_remove_profile}
            >
              <Typography style={{ color: "#FFFFFF" }}>{t("ok")}</Typography>
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default UserProfileInformation;
