import {
  date_format,
  ensure_https,
  split_content,
} from "@src/helpers/functions";
import { BROKEN_IMAGE } from "@src/helpers/image_constants";
import { useDynamicSelector } from "@src/services/redux";
import { ROUTES } from "@src/route/my_routes";
import { Typography } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const TrendingNowSearchResults = (props) => {
  const { list } = props;
  const navigate = useNavigate();
  const image_url = ensure_https(list?.thumbnail_img);
  const { dark_mode } = useDynamicSelector("dark_mode");

  const handle_redirect = () => {
    if (list?.record_type === "article") {
      const url = `${ROUTES.SINGLE_ARTICLE}/${list?.perma_link}.html`;
      window.open(url, "_blank");
      // navigate(`${ROUTES.SINGLE_ARTICLE}/${list?.perma_link}.html`);
    } else if (list?.record_type === "fact_check") {
      const url = `${ROUTES.SINGLE_FACT_CHECK}/${list?.perma_link}.html`;
      window.open(url, "_blank");
      // navigate(`${ROUTES.SINGLE_FACT_CHECK}/${list?.perma_link}.html`);
    }
  };

  return (
    <div className="fact_check_trending_container" onClick={handle_redirect}>
      <img
        alt={list?.title || "YouTurn Fact Check"}
        src={image_url || BROKEN_IMAGE}
        className="fact_trending_image"
      />

      <div className="fact_trending_details_container">
        <div className="home_article_trending_title_container">
          <Typography
            className={`home_trending_title_text ${
              dark_mode ? "dark_mode_card" : ""
            }`}
          >
            {split_content(list?.title, 0, 7)}
          </Typography>
        </div>
        <div className="home_article_trending_author_container">
          <Typography
            className={`home_trending_title_text ${
              dark_mode ? "dark_mode_card" : ""
            }`}
          >
            {`${
              list?.staff_profile?.name ||
              list?.user_profile?.name ||
              "youturn Editorial"
            } | ${date_format(list?.published_date_time)}`}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default TrendingNowSearchResults;
