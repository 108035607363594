import { date_format, ensure_https } from "@src/helpers/functions";
import { BROKEN_IMAGE } from "@src/helpers/image_constants";
import { ROUTES } from "@src/route/my_routes";
import { Button, Col, Row, Typography } from "antd";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { LuEye } from "react-icons/lu";
import { useNavigate } from "react-router-dom";

const HomeArticleSecondColumn = (props) => {
  const { list } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const formatted_date = list?.[0]?.published_date_time
    ? date_format(list?.[0]?.published_date_time)
    : date_format(list?.[0]?.created_date_time);

  const handle_click_page_redirect = () => {
    const url = `${ROUTES.SINGLE_ARTICLE}/${list?.[0]?.perma_link}.html`;
    window.open(url, "_blank");
    // navigate(`${ROUTES.SINGLE_ARTICLE}/${list?.[0]?.perma_link}.html`);
  };
  const image_url = ensure_https(list?.[0]?.thumbnail_img);

  return (
    <Row
      className="home_article_sec_column_inner_row_content"
      onClick={handle_click_page_redirect}
    >
      <img
        alt={list?.[0]?.title || "YouTurn Fact Check"}
        className="exposing_fake_news_first_column_image"
        src={image_url || BROKEN_IMAGE}
      />
      <Col className="home_article_second_column_details">
        <Row className="exposing_fake_news_first_column_details_row">
          <Typography className="exposing_fake_news_first_column_title">
            {list?.[0]?.title}
          </Typography>
        </Row>
        <Row className="exposing_fake_news_first_column_details_wrapper">
          <Col className="exposing_fake_news_first_column_view_count">
            <LuEye className="exposing_fake_news_first_column_view_icon" />
            <Typography className="home_article_second_column_writer_name_and_views">
              {list?.[0]?.views}
            </Typography>
          </Col>
          <Col className="home_article_second_column_writer_name_and_views">{`${
            list?.[0]?.staff_profile?.name ||
            list?.[0]?.user_profile?.name ||
            "youturn Editorial"
          } | ${formatted_date}`}</Col>
          <Col>
            <Button className="exposing_fake_news_first_column_button">
              {t("articles")}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default HomeArticleSecondColumn;
