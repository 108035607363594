import UserLayout from "@src/views/layouts/user_layout";
import AboutUs from "@src/views/pages/about_us";
import FactCheck from "@src/views/pages/fact_check";
import Home from "@src/views/pages/home";
import SearchResults from "@src/views/pages/search_results";
import SingleFactCheck from "@src/views/pages/single_fact_check";
import UserContribution from "@src/views/pages/user_contribution";
import Videos from "@src/views/pages/videos";
import Articles from "@src/views/pages/articles";
import SingleArticle from "@src/views/pages/single_article";
import UserProfile from "@src/views/pages/user_profile";
import WriterProfile from "@src/views/pages/writer_profile";
import ContactUs from "@src/views/pages/contact_us";
import SupportUs from "@src/views/pages/support_us";
import Login from "@src/views/pages/login_page";
import UserDonation from "@src/views/components/user_profile/donation/user_donation";
import UserClaims from "@src/views/components/user_profile/claims/user_claims";
import FactCheckContribution from "@src/views/components/user_contributions_form/fact_check_contribution";
import UserBookmarkArticles from "@src/views/pages/user_bookmark_articles";
import UserBookmarkFactChecks from "@src/views/pages/user_bookmark_fact_check";
import UserContributionFactCheck from "@src/views/pages/user_contribution_fact_check";
import UserContributionArticles from "@src/views/pages/user_contribution_article";
import ArticleCheckContribution from "@src/views/components/user_contributions_form/article_contribution";
import ForgotPassword from "@src/views/pages/forgot_password";
import TopContributors from "@src/views/pages/top_contributors";
import WriterContributionList from "@src/views/components/writer_profile/writer_contribution_list";
import PageNotFound from "@src/views/pages/page_not_found";
import NotFound from "@src/views/components/auth/not_found";
import ResetPassword from "@src/views/pages/reset_password";
import PoliticalInvolvementPolicy from "@src/views/pages/political_involvement_policy";
import CorrectionPolicy from "@src/views/pages/correction_policy";
import PrivacyPolicy from "@src/views/pages/privacy_policy";
import RssFeed from "@src/views/pages/rss_feed";
import { compose } from "@reduxjs/toolkit";
import RedirectHandler from "@src/views/pages/redirect_handler";

export const ROUTES = {
  HOME: "/home",
  VIDEOS: "/videos",
  ABOUT_US: "/about-us",
  SINGLE_FACT_CHECK: "/factcheck",
  SEARCH_RESULT_LIST: "/search-results",
  FACT_CHECK: "/fact-check",
  USER_CONTRIBUTION: "/user-contribution",
  ARTICLES: "/article",
  SINGLE_ARTICLE: "/articles",
  WRITER_PROFILE: "/writer-profile",
  USER_PROFILE: "/user-profile",
  CONTACT_US: "/contact-us",
  SUPPORT_US: "support-us",
  USER_DONATION: "/donations",
  LOGIN: "/login",
  USER_CLAIMS: "/claims",
  USER_ARTICLE_FORM: "/user-article-form",
  USER_FACT_CHECK_FORM: "/user-fact-check-form",
  BOOKMARK_ARTICLES: "/bookmark/articles",
  BOOKMARK_FACT_CHECK: "/bookmark/fact-check",
  USER_FACT_CHECKS: "/profiles/my-fact-check",
  USER_ARTICLES: "/profiles/my-articles",
  TOP_CONTRIBUTORS: "/top-contributors",
  FORGOT_PASSWORD: "/forgot-password",
  WRITER_CONTRIBUTION_LIST: "/writer-contribution-list",
  PAGE_NOT_FOUND: "/page-not-found",
  RESET_PASSWORD: "/reset-password",
  POLITICAL_INVOLVEMENT_POLICY: "/political-involvement-policy",
  CORRECTION_POLICY: "/correction-policy",
  PRIVACY_POLICY: "/privacy-policy",
  RSS_FEED:"/RSS",
  REDIRECT_HANDLER:"/p"
};

const my_routes = [
  {
    path: "/",
    name: "Layout",
    authenticate: false,
    component: <UserLayout />,
    children: [
      {
        path: "",
        name: "Home",
        authenticate: false,
        component: <Home />,
      },
      {
        path: ROUTES.HOME,
        name: "Home",
        authenticate: false,
        component: <Home />,
      },
      {
        path: ROUTES.VIDEOS,
        name: "Videos",
        authenticate: false,
        component: <Videos />,
      },
      {
        path: ROUTES.ABOUT_US,
        name: "About Us",
        authenticate: false,
        component: <AboutUs />,
      },
      
      {
        path: `${ROUTES.SINGLE_FACT_CHECK}/:perma_link?`,
        name: "Fact Check",
        authenticate: false,
        component: <SingleFactCheck />,
      },
      {
        path: ROUTES.SEARCH_RESULT_LIST,
        name: "Search Results",
        authenticate: false,
        component: <SearchResults />,
      },
      {
        path: ROUTES.FACT_CHECK,
        name: "Fact Check",
        authenticate: false,
        component: <FactCheck />,
      },
      {
        path: ROUTES.USER_CONTRIBUTION,
        name: "User Contribution",
        authenticate: false,
        component: <UserContribution />,
      },
      {
        path: ROUTES.ARTICLES,
        name: "Articles",
        authenticate: false,
        component: <Articles />,
      },
      {
        path: `${ROUTES.SINGLE_ARTICLE}/:perma_link`,
        name: "Article",
        authenticate: false,
        component: <SingleArticle />,
      },
      {
        path: `${ROUTES.WRITER_PROFILE}/:author_name?`,
        name: "WriterProfile",
        authenticate: false,
        component: <WriterProfile />,
      },
      {
        path: ROUTES.USER_PROFILE,
        name: "UserProfile",
        authenticate: false,
        component: <UserProfile />,
      },
      {
        path: ROUTES.CONTACT_US,
        name: "ContactUs",
        authenticate: false,
        component: <ContactUs />,
      },
      {
        path: ROUTES.SUPPORT_US,
        name: "SupportUs",
        authenticate: false,
        component: <SupportUs />,
      },
      {
        path: ROUTES.USER_DONATION,
        name: "Donation History",
        authenticate: false,
        component: <UserDonation />,
      },
      {
        path: ROUTES.CONTACT_US,
        name: "ContactUs",
        authenticate: false,
        component: <ContactUs />,
      },
      {
        path: ROUTES.SUPPORT_US,
        name: "SupportUs",
        authenticate: false,
        component: <SupportUs />,
      },
      {
        path: ROUTES.USER_DONATION,
        name: "Donation History",
        authenticate: false,
        component: <UserDonation />,
      },
      {
        path: ROUTES.USER_CLAIMS,
        name: "Claims",
        authenticate: false,
        component: <UserClaims />,
      },
      {
        path: `${ROUTES.USER_FACT_CHECK_FORM}/:id?`,
        name: "Fact Check",
        authenticate: false,
        component: <FactCheckContribution />,
      },
      {
        path: ROUTES.BOOKMARK_ARTICLES,
        name: "Bookmarks",
        authenticate: false,
        component: <UserBookmarkArticles />,
      },
      {
        path: ROUTES.BOOKMARK_FACT_CHECK,
        name: "Bookmarks",
        authenticate: false,
        component: <UserBookmarkFactChecks />,
      },
      {
        path: ROUTES.USER_FACT_CHECKS,
        name: "Fact Checks",
        authenticate: false,
        component: <UserContributionFactCheck />,
      },
      {
        path: ROUTES.USER_ARTICLES,
        name: "Fact Checks",
        authenticate: false,
        component: <UserContributionArticles />,
      },
      {
        path: `${ROUTES.USER_ARTICLE_FORM}/:id?`,
        name: "Article",
        authenticate: false,
        component: <ArticleCheckContribution />,
      },
      {
        path: `${ROUTES.TOP_CONTRIBUTORS}/:id?`,
        name: "Top Contributors",
        authenticate: false,
        component: <TopContributors />,
      },
      {
        path: `${ROUTES.WRITER_CONTRIBUTION_LIST}/:name`,
        name: "Writer Contribution List",
        authenticate: false,
        component: <WriterContributionList />,
      },
      {
        path: ROUTES.PAGE_NOT_FOUND,
        name: "Page Not Found",
        authenticate: false,
        component: <NotFound />,
      },
      {
        path: ROUTES.POLITICAL_INVOLVEMENT_POLICY,
        name: "Political Involvement Policy",
        authenticate: false,
        component: <PoliticalInvolvementPolicy />,
      },
      {
        path: ROUTES.CORRECTION_POLICY,
        name: "Correction Policy",
        authenticate: false,
        component: <CorrectionPolicy />,
      },
      {
        path: ROUTES.PRIVACY_POLICY,
        name: "Privacy Policy",
        authenticate: false,
        component: <PrivacyPolicy />,
      },
      // {
      //   path:`${ROUTES.REDIRECT_HANDLER}:/id`,
      //   name:"Redirect Handler",
      //   authenticate:false,
      //   component:<RedirectHandler/>
      // }
     
    ],
  },
  {
    path: `${ROUTES.RSS_FEED}`,
    name: "Rss Feed",
    authenticate: false,
    component: <RssFeed />,
  },
  {
    path: ROUTES.LOGIN,
    name: "Login",
    component: <Login />,
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    name: "Forgot Password",
    component: <ForgotPassword />,
  },
  {
    path: `${ROUTES.RESET_PASSWORD}/:token`,
    name: "Reset Password",
    component: <ResetPassword />,
  },
];

export default my_routes;
