import { BROKEN_IMAGE, USER_PROFILE } from "@src/helpers/image_constants";
import React from "react";
import { AiFillClockCircle } from "react-icons/ai";
import { LuEye } from "react-icons/lu";
import ReportShareContainerMobileView from "./report_share_container_mobile_view";
import { useDynamicSelector } from "@src/services/redux";
import {
  date_format,
  ensure_https,
  format_views_count,
  get_reading_time,
  reading_time,
} from "@src/helpers/functions";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useReadingTime } from "react-hook-reading-time";
import { readingTime } from "reading-time-estimator";
import { retrieve_item } from "@src/helpers/cryptos";

const SingleArticleWriterDetails = (props) => {
  const { show_report_popup } = props;
  const article = useDynamicSelector("get_article");
  const { t } = useTranslation();
  const navigate = useNavigate();
  let language = retrieve_item("language");

  const parser = new DOMParser();
  const doc = parser.parseFromString(article?.content, "text/html");

  const result = readingTime(
    doc?.body?.textContent,
    language?.name === "tamil" ? 20 : 100
  );

  const on_click_to_view_writer_details = () => {
    const queryParams = new URLSearchParams();
    if (article?.staff_profile?.id) {
      queryParams.set("staff_profile", article?.staff_profile?.id);
    }
    if (article?.user_profile?.id) {
      queryParams.set("user_profile", article?.user_profile?.id);
    }
    queryParams.set("type", "article");
    navigate({
      pathname: `/writer-profile/${
        article?.staff_profile?.name || article?.user_profile?.name
      }`,
      search: `?${queryParams.toString()}`,
    });
  };

  const image_url = ensure_https(
    article?.staff_profile?.profile_pic ||
      article?.user_profile?.profile_pic ||
      USER_PROFILE
  );

  return (
    <div className="writer_details">
      <div className="single_article_writer_details_container">
        <img
          alt={
            article?.staff_profile?.name ||
            article?.user_profile?.name ||
            "Youturn"
          }
          className="writer_profile_image"
          onClick={on_click_to_view_writer_details}
          src={image_url || BROKEN_IMAGE}
        />
        <div className="single_article_writer_details_wrapper">
          <text className="writer_name">
            {article?.staff_profile?.name ||
              article?.user_profile?.name ||
              "Youturn Editorial"}
          </text>
          <text className="published_date">
            {date_format(article?.published_date_time)}
          </text>
          <div className="single_article_view_and_reading_time">
            <div className="single_article_icons">
              <LuEye color="red" />
              <text className="single_article_view_count">
                {format_views_count(article?.views)}
              </text>
            </div>
            <div className="single_article_icons">
              <AiFillClockCircle color="gray" />
              <text className="single_article_reading_time">
                {get_reading_time(article?.reading_time, t)}
              </text>
            </div>
          </div>
        </div>
      </div>
      <div className="report_share_container">
        <ReportShareContainerMobileView show_report_popup={show_report_popup} />
      </div>
    </div>
  );
};

export default SingleArticleWriterDetails;
