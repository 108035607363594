import { FOOTER_LOGO_ICON } from "@src/helpers/image_constants";
import { ROUTES } from "@src/route/my_routes";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";

const FooterMobileViewThirdColumn = () => {
  const navigate = useNavigate();
  const get_year = moment().year();
  const handle_home = () => {
    navigate(ROUTES.HOME);
  };
  return (
    <div className="footer_mobile_third_column_container">
      <img
        alt={"Footer Logo"}
        src={FOOTER_LOGO_ICON}
        className="footer_first_column_footer_logo_image"
        onClick={handle_home}
      />
      <div className="copy_right_container">
        <h4 className="copy_right_text">
          ©Copyright {get_year} All Rights Reserved
        </h4>
      </div>
    </div>
  );
};

export default FooterMobileViewThirdColumn;
