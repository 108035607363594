import React, { useEffect, useRef, useState } from "react";
import TrendingResults from "@src/views/components/search_results/trending_results";
import RecentFactCheckResults from "@src/views/components/search_results/recent_fact_check_results";
import SearchBar from "../components/search_results/search_bar";
import Results from "../components/search_results/results";
import { debounce } from "lodash";
import {
  get_recent_fact_check_result_list_query,
  get_search_result_list_query,
} from "@src/services/graphql/graphql_search_results";
import { useAPIRequest } from "@src/helpers/hooks";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { retrieve_item } from "@src/helpers/cryptos";
import { useDynamicSelector } from "@src/services/redux";
import JoinUs from "../components/custom_components/join_us/join_us";

const SearchResults = () => {
  const { t } = useTranslation();
  const search_string = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const top_ref = useRef(null);
  const queryParams = new URLSearchParams(location.search);
  const search_text = queryParams.get("type");
  const language = retrieve_item("language");

  const [page_limit, set_page_limit] = useState(8);
  const [result_type, set_result_type] = useState("fact_check");
  const [search_items, set_search_items] = useState([]);

  const [search_fact_check_items, set_search_fact_check_items] = useState([]);

  const {
    items: search_results_items,
    pagination: search_results_items_pagination,
  } = useDynamicSelector("get_search_result_list");

  const handle_page_position = () => {
    const element = top_ref?.current;
    element?.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const debounced_handle_title_click = debounce(handle_page_position, 800);

  useEffect(() => {
    get_recent_fact_check_result_list();
    debounced_handle_title_click();
  }, []);

  useEffect(() => {
    fetch_data();
  }, [search_text, page_limit]);

  useEffect(() => {
    fetch_data();
  }, [result_type]);

  useEffect(() => {
    if (search_results_items && result_type === "article") {
      set_search_items(search_results_items);
    } else if (search_results_items && result_type === "fact_check") {
      set_search_fact_check_items(search_results_items);
    }
  }, [search_results_items]);

  let recent_fact_check_result_list_request = useAPIRequest(
    "get_recent_fact_check_result_list",
    get_recent_fact_check_result_list_query
  );
  let get_search_results = useAPIRequest(
    "get_search_result_list",
    get_search_result_list_query
  );

  const get_recent_fact_check_result_list = () => {
    recent_fact_check_result_list_request(
      { language_id: language?.id, page_number: 1, page_limit: 10 },
      true
    );
  };
  const fetch_data = () => {
    get_search_results(
      {
        search_string: search_text,
        page_limit: page_limit,
        language_id: language?.id,
        type: result_type,
      },
      true
    );
  };

  const load_more_results = () => {
    set_page_limit(page_limit + 8);
  };

  return (
    <div>
      <SearchBar search_text={search_text} />

      <Results
        load_more_results={load_more_results}
        set_result_type={set_result_type}
        set_search_items={set_search_items}
        set_search_fact_check_items={set_search_fact_check_items}
      />
      <RecentFactCheckResults />
      <JoinUs />
    </div>
  );
};

export default SearchResults;
