import { UploadOutlined } from "@ant-design/icons";
import { get_uuid } from "@src/helpers/functions";
import { query_request } from "@src/services/apollo/api_service";
import { file_upload_query } from "@src/services/graphql/graphql_file_upload";
import { Button, Form, Upload, message } from "antd";
import axios from "axios";
import { t } from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const UploadFile = () => {
  const { t } = useTranslation();
  const [file_list, set_file_list] = useState({});
  const [error_message, set_error_message] = useState("");

  let file_upload_response = "";
  const action = file_upload_response?.data?.get_upload_url?.url;

  const handle_upload = ({ onSuccess, onError, file }) => {
    axios
      .put(file_list?.[0]?.url, file, {
        headers: { "Content-Type": file.type },
      })
      .then((response) => {
        file.url = file_list?.[0]?.url.split("?")[0];

        onSuccess(null, file);
      })
      .catch((error) => {});
  };

  const handle_before_upload = async (file) => {
    const { name, type } = file;
    const allowed_types = [
      "application/pdf",
      "image/png",
      "image/jpg",
      "image/jpeg",
    ];
    const valid_extensions = [".pdf", ".png", ".jpg", ".jpeg"];
    const is_valid_extensions = valid_extensions.some((ext) =>
      name.endsWith(ext)
    );
    if (is_valid_extensions || allowed_types.includes(type)) {
      let file_upload = {
        type: "document",
        fileName: file.name,
      };

      file_upload_response = await query_request(
        file_upload_query,
        file_upload
      );
      set_error_message("");
    } else {
      set_error_message(
        t(
          "Invalid file format. You can only upload PDF, PNG, JPG, or JPEG files."
        )
      );
      return Upload.LIST_IGNORE;
    }
  };

  const handle_file_changed = ({ file }) => {
    if (file?.status === "removed") {
      set_file_list([]);
    } else if (file?.status === "uploading") {
      file = {
        ...file,
        url: file_upload_response?.data?.get_upload_url?.url,
      };
      set_file_list([file]);
      return file;
    } else if (file?.status === "done") {
      set_file_list([...file_list]);
      return file;
    }
  };
  return (
    <Form.Item
      name="attachment"
      validateStatus={error_message ? "error" : ""}
      help={error_message}
    >
      <Upload
        name="file"
        action={action}
        customRequest={(e) => handle_upload(e)}
        beforeUpload={(args) => handle_before_upload(args)}
        onChange={(e) => handle_file_changed(e)}
      >
        <Button icon={<UploadOutlined />}>{t("click_to_upload")}</Button>
      </Upload>
    </Form.Item>
  );
};

export default UploadFile;
