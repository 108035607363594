import { retrieve_item } from "@src/helpers/cryptos";
import { useAPIRequest } from "@src/helpers/hooks";
import { get_slot_list_query } from "@src/services/graphql/graphql_home";
import { useDynamicSelector } from "@src/services/redux";
import React, { useEffect } from "react";
import HomeSlotCard from "./home_slots/home_slot_card";
import HomeIfcnLogoAndContent from "./home_slots/home_ifcn_logo_and_content";
import HomeSlotsInMobile from "./home_slots/home_slots_in_mobile";
import { SIGNATORY } from "@src/helpers/image_constants";
import FactCheckMiddleCardSkeleton from "./home_fact_check/home_fact_check_loading/fact_check_middle_card_skeleton";
import SingleArticleFirstColumnSkeleton from "../single_article/single_article_details/single_article_first_column_skeleton";
import FeaturedArticleImageComponentSkeleton from "../articles/featured_article/featured_article_skeleton_loading/featured_article_image_component_skeleton";

const HomeSlots = () => {
  const language = retrieve_item("language");

  const { items: slot_list, loading: slot_list_loading } =
    useDynamicSelector("get_slot_list");

  let home_slot_list_request = useAPIRequest(
    "get_slot_list",
    get_slot_list_query
  );

  useEffect(() => {
    get_slot_list();
  }, []);

  const get_slot_list = () => {
    home_slot_list_request({ language_id: language?.id }, true);
  };

  const handle_redirect = () => {
    window.open("https://ifcncodeofprinciples.poynter.org/profile/youturn");
  };
  return (
    <div className="home_slots_content_container">
      <div className="hide_content_in_desktop">
        {slot_list_loading ? (
          <FeaturedArticleImageComponentSkeleton />
        ) : (
          <HomeSlotsInMobile
            slot_list={slot_list}
            slot_list_loading={slot_list_loading}
          />
        )}
      </div>
      <div className="home_slots_background_container">
        <div className="home_slots_background_image_container">
          <img
            alt={"Signatory Image"}
            className="signatory_logo"
            src={SIGNATORY}
            onClick={handle_redirect}
          />
        </div>
      </div>
      <div className="slots_card_wrapper">
        <HomeIfcnLogoAndContent />

        {slot_list_loading ? (
          <>
            <FactCheckMiddleCardSkeleton />
            <FactCheckMiddleCardSkeleton />
            <FactCheckMiddleCardSkeleton />
          </>
        ) : (
          <HomeSlotCard slot_list={slot_list} />
        )}
      </div>
    </div>
  );
};

export default HomeSlots;
