import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Form } from "antd";
import { YOU_TURN_LOGO_BLUE } from "../../helpers/image_constants";
import { queryStringRetrieve1, store_item } from "@src/helpers/cryptos";
import SignUpMainForm from "../components/login/sign_up_form/sign_up_main_form";
import SignInFormMobileView from "../components/login/sign_up_form/sign_in_form_mobile_view";
import OtpForm from "../components/login/sign_up_form/otp_form";
import LogoImageForHomePage from "../components/login/sign_up_form/logo_image_form_home";
import { mutation_sign_up } from "@src/services/graphql/graphql_sign_up";
import { ROUTES } from "@src/route/my_routes";
import { dynamic_clear, useDynamicSelector } from "@src/services/redux";
import { useAPIRequest } from "@src/helpers/hooks";
import { all_user_login_query } from "@src/services/graphql/graphql_login";
import { add_article_book_mark_mutation } from "@src/services/graphql/graphql_single_article";
import { add_fact_check_book_mark_mutation } from "@src/services/graphql/graphql_single_fact_check";

const Login = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [register_form] = Form.useForm();
  const [password_visible, set_password_visible] = useState(false);
  const [otp, set_otp] = useState(false);
  const [form_submitted, set_form_submitted] = useState(true);
  const [hide_form, set_hide_form] = useState(true);
  const location = useLocation();

  const navigate = useNavigate();
  const {
    status: create_user_status,
    error: create_user_error,
    loading: create_user_loading,
    user_id,
    name,
    mobile,
    email,
    force_password_change,
    username,
    is_subscribed,
    session_id,
    page_accesses,
    page_component_accesses,
    is_active,
    roles,
  } = useDynamicSelector("login");
  let signUpRequest = useAPIRequest("sign_up", mutation_sign_up);
  let loginRequest = useAPIRequest("login", all_user_login_query);

  let add_article_bookmark_request = useAPIRequest(
    "add_article_to_saved_items",
    add_article_book_mark_mutation
  );

  let add_fact_check_book_mark_request = useAPIRequest(
    "add_fact_check_to_saved_items",
    add_fact_check_book_mark_mutation
  );

  const {
    status: create_status,
    error: create_error,
    loading: sing_up_loading,
  } = useDynamicSelector("sign_up");

  let chosen_language_key = window.sessionStorage.getItem(
    "chosen_language_key"
  );

  useEffect(() => {
    document.title = "Login";
  }, []);

  useEffect(() => {
    if (create_user_status === "Success") {
      store_item("session_id", session_id);
      store_item("user_id", user_id);
      store_item("name", name);
      store_item("email", email);
      store_item("mobile", mobile);
      store_item("force_password_change", force_password_change);
      store_item("username", username);
      store_item("is_subscribed", is_subscribed);
      store_item("page_accesses", page_accesses);
      store_item("page_component_accesses", page_component_accesses);
      store_item("is_active", is_active);
      store_item("roles", roles);
      store_item("role", roles[0]?.name);
      store_item("role_id", roles[0]?.id);
      form.resetFields("");

      if (roles[0]?.name === "Admin") {
        navigate(ROUTES.ADMIN_FACT_CHECK);
      } else if (roles[0]?.name === "Editor") {
        navigate(ROUTES.ADMIN_FACT_CHECK);
      } else if (roles[0]?.name === "Previleged User") {
        navigate(ROUTES.HOME);
      } else if (roles[0]?.name === "User") {
        if (location.search) {
          let type1 = queryStringRetrieve1("type", location.search);
          let type2 = queryStringRetrieve1("types", location.search);
          let id = queryStringRetrieve1("id", location.search);
          let redirect_url = queryStringRetrieve1(
            "redirecturl",
            location.search
          );
          if (type1 === "bookmark" && type2 === "article") {
            add_article_in_book_mark(id, type2);
          } else if (type1 === "bookmark" && type2 === "fact-check") {
            add_fact_check_in_book_mark(id);
          } else if (type1 === "report") {
            navigate(redirect_url);
          }
          navigate(redirect_url);
        } else {
          navigate(ROUTES.HOME, {
            chosen_language_key: chosen_language_key,
          });
        }
      } else {
        navigate(ROUTES.HOME, {
          chosen_language_key: chosen_language_key,
        });
      }
    } else {
      dispatch(dynamic_clear("admin_login"));
    }
  }, [create_user_error, session_id]);

  useEffect(() => {
    if (create_status === "Success") {
      register_form.resetFields("");
      // toast.success("Excited to have you join us!");
      sign_in();
      dispatch(dynamic_clear("sign_up"));
    } else if (create_status === "Failure") {
      // toast.error(create_error.message);
      dispatch(dynamic_clear("sign_up"));
    }
    dispatch(dynamic_clear("sign_up"));
  }, [create_status, create_error]);

  const add_article_in_book_mark = (id, type) => {
    add_article_bookmark_request(
      {
        article_id: id,
      },
      true
    );
  };

  const add_fact_check_in_book_mark = (id) => {
    add_fact_check_book_mark_request(
      {
        fact_check_id: id,
      },
      true
    );
  };

  const on_finish = (values) => {
    login(values);
  };

  const forgot_click = () => {
    navigate(ROUTES.FORGOT_PASSWORD);
  };

  const login = (values) => {
    loginRequest(
      {
        username: values?.username,
        password: values?.password,
      },
      true
    );
  };

  const mutation_sign_in = (values) => {
    signUpRequest(
      {
        data: {
          name: values?.name,
          email: values?.email,
          mobile: values?.mobile,
          username: values?.email,
          password: values?.password,
        },
      },
      true
    );
  };
  useEffect(() => {
    if (create_user_status === "Success") {
      form.resetFields("");
      // toast.success(`Welcome back! ${name}`);
      dispatch(dynamic_clear("login"));
    } else if (create_user_status === "Failure") {
      // toast.error(create_user_error.message);
      // dispatch(dynamic_clear("login"));
    }
  }, [create_user_status, create_user_error]);

  const sign_in = (event) => {
    form.resetFields("");
    const container = document.getElementById("container");
    container.classList.remove("active");
    event?.preventDefault();
    set_otp(false);
    set_form_submitted(true);
    set_hide_form(true);
  };

  const sign_up = (event) => {
    register_form.resetFields("");
    event?.preventDefault();
    const container = document.getElementById("container");
    container.classList.add("active");
  };

  const toggle_password_visibility = () => {
    set_password_visible(!password_visible);
  };

  useEffect(() => {
    dispatch(dynamic_clear("get_user"));
  }, []);

  return (
    <div className="login_page_main_container">
      <LogoImageForHomePage />
      <div className="container" id="container">
        <SignUpMainForm
          toggle_password_visibility={toggle_password_visibility}
          password_visible={password_visible}
          sign_in={sign_in}
          otp={otp}
          mutation_sign_in={mutation_sign_in}
          hide_form={hide_form}
          set_hide_form={set_hide_form}
          set_otp={set_otp}
        />
        <OtpForm
          otp={otp}
          on_finish={on_finish}
          sign_up={sign_up}
          forgot_click={forgot_click}
          form={form}
          password_visible={password_visible}
          toggle_password_visibility={toggle_password_visibility}
        />
        <SignInFormMobileView sign_in={sign_in} sign_up={sign_up} />
      </div>
    </div>
  );
};
export default Login;
