import {
  FAKE_NEWS,
  FALSE_NEWS,
  MISLEADING_NEWS,
  MIX_NEWS,
  SARCASM_NEWS,
  TRUE_NEWS_ICON,
} from "@src/helpers/image_constants";
import React from "react";
import { useTranslation } from "react-i18next";

function FactCheckItems() {
  const { t } = useTranslation();
  const fact_check = [
    {
      description: t("true_news"),
      image_url: TRUE_NEWS_ICON,
    },
    {
      description: t("fake_news"),
      image_url: FAKE_NEWS,
    },
    {
      description: t("mix_news"),
      image_url: MIX_NEWS,
    },
    {
      description: t("sarcasm_news"),
      image_url: SARCASM_NEWS,
    },
    {
      description: t("misleading_news"),
      image_url: MISLEADING_NEWS,
    },
    {
      description: t("false_news"),
      image_url: FALSE_NEWS,
    },
  ];
  return (
    <div className="fact_check_items_container">
      {fact_check.map((item, i) => (
        <div key={i} className="fact_check_item">
          <img
            src={item.image_url}
            alt="YouTurn Fact Check"
            className="fact_check_image_one"
          />
          <p className="fact_check_description_one">{item.description}</p>
        </div>
      ))}
    </div>
  );
}

export default FactCheckItems;
