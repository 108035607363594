import React from "react";
import { IFCN_LOGO, PUBLIC_SPIRITED_IMAGE } from "@src/helpers/image_constants";
import { useTranslation } from "react-i18next";

function IfcnCertified() {
  const { t } = useTranslation();

  let contents = [
    {
      image: IFCN_LOGO,
      title: t("ifcn_certified"),
      content: t("ifcn_certified_content"),
    },
    {
      image: PUBLIC_SPIRITED_IMAGE,
      // image: IFCN_LOGO,
      title: t("independent_and_public_spirited_media_function"),
      content: t("independent_and_public_spirited_media_function_content"),
    },
  ];
  const handle_redirect = (url) => {
    window.open(url);
  };
  return (
    <div className="ifcn_main_container">
      <div className="ifcn_container">
        <img
          src={IFCN_LOGO}
          alt="ifcn image"
          className="ifcn_image_desktop"
          onClick={() => {
            handle_redirect(
              "https://ifcncodeofprinciples.poynter.org/profile/youturn"
            );
          }}
        />
        <div>
          <p className="ifcn_heading">{t("ifcn_certified")}</p>
          <div className="ifcn_image_mobile">
            <img
              src={IFCN_LOGO}
              alt="ifcn image"
              className="ifcn_image"
              onClick={() => {
                handle_redirect(
                  "https://ifcncodeofprinciples.poynter.org/profile/youturn"
                );
              }}
            />
          </div>
          <p className="ifcn_description">{t("ifcn_certified_content")}</p>
        </div>
      </div>
      <div className="ifcn_container">
        <img
          src={PUBLIC_SPIRITED_IMAGE}
          alt="ifcn image"
          className="public_spirited_image_desktop"
          style={{ objectFit: "none", cursor: "pointer" }}
          onClick={() => handle_redirect("https://ipsmf.org/")}
        />
        <div>
          <p className="ifcn_heading">
            {t("independent_and_public_spirited_media_function")}
          </p>
          <div className="public_spirited_image_mobile">
            <img
              src={PUBLIC_SPIRITED_IMAGE}
              alt="ifcn image"
              className="ifcn_image"
              onClick={() => {
                handle_redirect(
                  "https://ifcncodeofprinciples.poynter.org/profile/youturn"
                );
              }}
            />
          </div>
          <p className="ifcn_description">
            {t("independent_and_public_spirited_media_function_content")}
          </p>
        </div>
      </div>
    </div>
  );
}

export default IfcnCertified;
