import { retrieve_item } from "@src/helpers/cryptos";
import {
  date_format,
  ensure_https,
  format_views_count,
  get_reading_time,
  split_title,
} from "@src/helpers/functions";
import { BROKEN_IMAGE } from "@src/helpers/image_constants";
import { ROUTES } from "@src/route/my_routes";
import React from "react";
import { useTranslation } from "react-i18next";
import { AiFillClockCircle } from "react-icons/ai";
import { LuEye } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { readingTime } from "reading-time-estimator";

const StoryCard = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let language = retrieve_item("language");
  const {
    thumbnail_img,
    published_date_time,
    title,
    height,
    sub_title,
    user_profile,
    staff_profile,
    view_count,
    loading,
    perma_link,
    img_height,
    img_width,
    reading_time,
    views,
    type,
  } = props;

  // const parser = new DOMParser();
  // const doc = parser.parseFromString(content, "text/html");
  // const result = readingTime(
  //   doc?.body?.textContent,
  //   language?.name === "tamil" ? 20 : 100
  // );

  const handle_redirect = () => {
    if (props?.type !== "fact_check") {
      const url = `${ROUTES.SINGLE_ARTICLE}/${perma_link}.html`;
      window.open(url, "_blank");
      // navigate(`${ROUTES.SINGLE_ARTICLE}/${perma_link}.html`);
    } else {
      const url = `${ROUTES.SINGLE_FACT_CHECK}/${perma_link}.html`;
      window.open(url, "_blank");
      // navigate(`${ROUTES.SINGLE_FACT_CHECK}/${perma_link}.html`);
    }
  };

  const image_url = ensure_https(thumbnail_img);

  return (
    <div
      className="user_contribution_mobile_card_container border-shadow"
      onClick={handle_redirect}
      style={{ height: height ?? "" }}
    >
      <img
        alt={title || "YouTurn"}
        src={image_url || BROKEN_IMAGE}
        className="user_contribution_mobile_card_image"
      />
      <div className="display-flex flex-column user_contributed_story_container_footer home_user_contribute_container user_contribution_mobile_card_footer">
        <div className="display-flex flex-row justify-content-between">
          <h6 className="contribute_user_name">
            {user_profile?.name || staff_profile?.name}
          </h6>
          <div className="display-flex flex-row g-1  align-center home_user_contribute_footer">
            <LuEye color="red" className="view-count-icon" />
            <text className="view-count-tag">{format_views_count(views)}</text>
          </div>
        </div>
        <div className="display-flex flex-row justify-content-between">
          <h6 className="user_contribute_publish_time">
            {date_format(published_date_time)}
          </h6>
          <div className="display-flex flex-row g-1 align-center home_user_contribute_footer">
            <AiFillClockCircle color="gray" className="reading-time-icon" />
            <text className="time-tag">
              {get_reading_time(reading_time, t)}
            </text>
          </div>
        </div>
      </div>
      <h3 className="home_user_contribute_mobile_title">
        {split_title(title)}
      </h3>
    </div>
  );
};

export default StoryCard;
