import React from "react";
import { useTranslation } from "react-i18next";
import CustomJoinWhatsappChannel from "../custom_components/whats_app/whats_app";
import { useDynamicSelector } from "@src/services/redux";
import {
  FAKE_NEWS,
  FALSE_NEWS,
  MISLEADING_NEWS,
  MIX_NEWS,
  SARCASM_NEWS,
  TRUE_NEWS_ICON,
} from "@src/helpers/image_constants";
import SingleFactCheckRatingSkeleton from "./single_fact_check_rating_skeleton";

const SingleFactCheckClaim = () => {
  const { t } = useTranslation();
  const { is_fact, loading: ger_all_fact_check_loading } =
    useDynamicSelector("get_fact_check");

  const image_source_map = {
    true: TRUE_NEWS_ICON,
    false: FALSE_NEWS,
    fake: FAKE_NEWS,
    mix: MIX_NEWS,
    sarcasm: SARCASM_NEWS,
    misleading: MISLEADING_NEWS,
  };
  const image_source = image_source_map[is_fact] || null;

  return (
    <div>
      <div className="single_fact_claim_title">
        <h5>{t("youTurn_rating")}</h5>
      </div>
      {ger_all_fact_check_loading ? (
        <SingleFactCheckRatingSkeleton />
      ) : (
        <div className="single_fact_check_rating_container">
          <img
            alt={"YouTurn Rating"}
            visible={false}
            preview={false}
            src={image_source}
            className="youTurn_rating_image"
          />
        </div>
      )}

      <div className="single_fact_check_whats_app_container">
        <CustomJoinWhatsappChannel />
      </div>
    </div>
  );
};

export default SingleFactCheckClaim;
