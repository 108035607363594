import {
  date_format,
  ensure_https,
  split_tamil_english_words,
} from "@src/helpers/functions";
import { Button } from "antd";
import React from "react";
import UserContributionListSkeletonLoading from "../../user_contribution.jsx/user_contribution_list_skeleton_loading";
import { useTranslation } from "react-i18next";
import { BROKEN_IMAGE } from "@src/helpers/image_constants";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@src/route/my_routes";
import SearchResultsListSkeletonLoading from "../common_results_container/search_results_list_skeleton_loading";

const CommonResultsContainerMobileView = (props) => {
  const {
    all_stories_list,
    handle_page_limit,
    user_contribution_list_loading,
    search_results_items_total_count,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handle_redirect = (item) => {
    if (item?.record_type === "article") {
      const url = `${ROUTES.SINGLE_ARTICLE}/${item?.perma_link}.html`;
      window.open(url, "_blank");
      // navigate(`${ROUTES.SINGLE_ARTICLE}/${item?.perma_link}.html`);
    } else {
      const url = `${ROUTES.SINGLE_FACT_CHECK}/${item?.perma_link}.html`;
      window.open(url, "_blank");
      // navigate(`${ROUTES.SINGLE_FACT_CHECK}/${item?.perma_link}.html`);
    }
  };

  return (
    <div>
      {all_stories_list?.map((item, index) => {
        const image_url = ensure_https(item?.thumbnail_img);

        return (
          <div
            className="search_results_list_container_card_mobile"
            key={index}
            onClick={() => handle_redirect(item)}
          >
            <img
              alt={item?.title || "YouTurn Article"}
              src={image_url || BROKEN_IMAGE}
              className="search_results_image image_hover_effect"
            />

            <div className="search_results_text_container">
              <div className="card_title_container">
                <span className="card_title">
                  {split_tamil_english_words(item?.title, "_", 7)}
                </span>
              </div>
              <div className="author_name_container">
                <span className="author_text">
                  {item?.user_profile?.name || item?.staff_profile?.name}
                </span>
                <span className="date_text">
                  {date_format(item?.published_date_time)}
                </span>
              </div>
            </div>
          </div>
        );
      })}
      {user_contribution_list_loading && (
        <div
          style={{
            width: "100%",
          }}
        >
          <SearchResultsListSkeletonLoading />
        </div>
      )}

      {all_stories_list?.length !== search_results_items_total_count && (
        <div className="load_more_container">
          <Button
            style={{
              width: "200px",
            }}
            onClick={handle_page_limit}
            className="load_more_button"
          >
            {t("load_more")}
          </Button>
        </div>
      )}
    </div>
  );
};
export default CommonResultsContainerMobileView;
