import { ensure_https } from "@src/helpers/functions";
import { BROKEN_IMAGE } from "@src/helpers/image_constants";
import { useDynamicSelector } from "@src/services/redux";
import React from "react";

const SingleArticleSecondColumn = () => {
  const article = useDynamicSelector("get_article");
  const image_url = ensure_https(article.thumbnail_img);

  return (
    <div className="single_article_second_column_container">
      <img
        alt={article.title || "YouTurn Article"}
        style={{ width: "100%" }}
        src={image_url || BROKEN_IMAGE}
      />
    </div>
  );
};

export default SingleArticleSecondColumn;
