import {
  NOTIFICATION_ICON,
  SUBSCRIBE_ICON,
  WRITING_IMAGE,
} from "@src/helpers/image_constants";
import { Button, Modal, notification, Tooltip } from "antd";
import React, { useState } from "react";
import SubmitClaimForm from "./submit_claim_form";
import { useTranslation } from "react-i18next";
import { IoMdClose } from "react-icons/io";
import { retrieve_item } from "@src/helpers/cryptos";
import { useNavigate } from "react-router-dom";
const SubmitClaim = ({ writing_image_opacity }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [claim_form_open, set_claim_form_open] = useState(false);
  const [show_popup, set_show_popup] = useState(false);

  const user_id = retrieve_item("user_id");
  const is_subscribed = retrieve_item("is_subscribed");
  const session_id = retrieve_item("session_id");

  const on_click_writing_image = () => {
    set_claim_form_open(true);
  };

  const on_close_modal = () => {
    set_claim_form_open(false);
  };

  // Register the service worker
  async function registerServiceWorker() {
    if ("serviceWorker" in navigator) {
      try {
        const registration = await navigator.serviceWorker.register(
          "/service-worker.js"
        );
        console.log(
          "Service Worker registered with scope:",
          registration.scope
        );
        return registration; // Correctly return the registration object
      } catch (error) {
        console.error("Service Worker registration failed:", error);
      }
    }
    return null;
  }

  // Convert the VAPID public key from base64 to Uint8Array
  function urlBase64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, "+")
      .replace(/_/g, "/");
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }

    return outputArray;
  }

  // Subscribe the user to push notifications
  async function subscribeUserToPush(registration) {
    const publicVapidKey =
      "BPtcilrMjeOnYVrtcNatFpqb3wQPtg-p2a-00wfT-rJEcngS_ey7N8flyIrO1WGfgrAu1zbEKuoLKrUdHPoWum0"; // Replace with your actual public VAPID key

    try {
      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
      });

      console.log("Push subscription object:", subscription);
      return subscription;
    } catch (error) {
      console.error("Failed to subscribe the user:", error);
    }
    return null;
  }

  // Handle the subscription and send to the backend
  const on_click_to_subscribe = async () => {
    if (session_id) {
      try {
        const registration = await registerServiceWorker(); // Get the service worker registration
        console.log("Service Worker registration:", registration);

        if (!registration) {
          console.error("Service Worker registration failed.");
          return;
        }

        // Subscribe to Push Notifications
        const subscription = await subscribeUserToPush(registration);
        console.log("Subscription object:", subscription);

        if (subscription) {
          const payload = {
            system_user_id: user_id, // Include user_id in the payload
            subscription,
          };
          // Send the subscription object to the backend
          const response = await fetch(
            "https://dev.vertace.org/youturn-sit/api/rest/subscribe",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(payload),
            }
          );

          if (response.ok) {
            console.log("Subscription sent to backend!");
          } else {
            console.error("Failed to send subscription to backend.");
          }
        }
      } catch (error) {
        console.error("Push subscription error:", error);
      }
    } else {
      set_show_popup(true);
      setTimeout(() => {
        set_show_popup(false);
      }, 3000);
    }
  };

  return (
    <>
      <div
        className="writing_image_div"
        style={{ opacity: writing_image_opacity }}
      >
        <img
          alt={"writing_icon"}
          src={WRITING_IMAGE}
          className="writing_image"
          onClick={on_click_writing_image}
        />
        {/* {(!session_id || (!is_subscribed && session_id)) && (
          <Tooltip
            title={"Please Login"}
            id="primary-card-tooltip"
            open={show_popup}
          >
            <div className="bell_image" onClick={on_click_to_subscribe}>
              <img
                alt={"bell_image"}
                src={SUBSCRIBE_ICON}
                className="bell_icon"
              />
            </div>
          </Tooltip>
        )} */}
      </div>

      <Modal
        className="submit_claim_modal"
        // title={t("submit_claim")}
        open={claim_form_open}
        onCancel={on_close_modal}
        destroyOnClose={true}
        footer={null}
        closeIcon={<IoMdClose className="report_modal_close_icon" />}
      >
        <SubmitClaimForm on_close_modal={on_close_modal} />
      </Modal>
    </>
  );
};
export default SubmitClaim;
