import moment from "moment";

export const fact_check_schema = (value) => {
  return {
    "@context": "https://schema.org",
    "@type": "ClaimReview",
    datePublished: moment(new Date(Number(value?.published_date_time))).format(
      "DD-MM-YYYY"
    ),
    url: window.location.href,
    author: {
      "@type": "Organization",
      url: window.location.href,
    },
    claimReviewed: value?.subtitle || "",
    reviewBody: "This claim is true. The UK also played a role.",
    itemReviewed: {
      "@type": "Clip",
      startOffset: "350",
      endOffset: "370",
      author: {
        "@type": "Person",
        name: value?.staff_profile?.name || "",
        jobTitle: "Editor",
        image: value?.staff_profile?.profile_pic || "",
        sameAs: value?.staff_profile?.url
          ? JSON.parse(value?.staff_profile?.url)
          : "",
      },
      name: value?.title || "",
    },
  };
};

export const article_schema = (value) => {
  return {
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    url: window.location.href,
    publisher: {
      "@type": "Organization",
      name: "Youturn",
      logo: "https://youturn.in/static/media/you_turn_footer_logo.cd77e868503b375860a0.png",
    },
    headline: value?.title,
    mainEntityOfPage: window.location.href,
    articleBody:
      "Director Comey says the probe into last year's US election would assess if crimes were committed.",
    image: value?.thumbnail_img,
    datePublished: moment(new Date(Number(value?.published_date_time))).format(
      "YYYY-MM-DDTHH:mm:ssZ"
    ),
  };
};

export const video_schema = (video) => {
  return {
    "@context": "https://schema.org/",
    "@type": "VideoObject",
    name: `${video?.title}`,
    description: `${video?.description}`,
    "@id": `https://www.youtube.com/watch?v=${video?.video_id}`,
    datePublished: `${moment(Number(video?.published_at)).format(
      "YYYY-MM-DDTHH:mm:ssZ"
    )}`,
    thumbnailUrl: `${video?.thumbnails}`,
    interactionStatistic: [
      {
        "@type": "InteractionCounter",
        interactionType: "https://schema.org/WatchAction",
        userInteractionCount: `${video?.views}`,
      },
    ],
  };
};
