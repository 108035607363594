import { retrieve_item } from "@src/helpers/cryptos";
import {
  date_format,
  ensure_https,
  split_featured_content,
  split_tamil_english_words,
  split_title,
} from "@src/helpers/functions";
import { BROKEN_IMAGE } from "@src/helpers/image_constants";
import { ROUTES } from "@src/route/my_routes";
import { useDynamicSelector } from "@src/services/redux";
import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { LuEye } from "react-icons/lu";
import { useNavigate } from "react-router-dom";

const FeaturedFactCheckImageComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let language = retrieve_item("language");
  const { items: featured_fact_check, loading: featured_fact_check_loading } =
    useDynamicSelector("get_featured_fact_check");
  const image_url = ensure_https(featured_fact_check?.[0]?.thumbnail_img);
  const formatted_date = featured_fact_check?.[0]?.published_date_time
    ? date_format(featured_fact_check?.[0]?.published_date_time)
    : date_format(featured_fact_check?.[0]?.created_date_time);

  const handle_redirect = (type, perma_link) => {
    // if (type === "article") {
    //   navigate(`${ROUTES.SINGLE_ARTICLE}/${perma_link}.html`);
    // } else {
    const url = `${ROUTES.SINGLE_FACT_CHECK}/${featured_fact_check?.[0]?.perma_link}.html`;
    window.open(url, "_blank");
  };

  const title =
    " On May 19 two people were killed in Pune s Kalyani Nagar after a Porsche purportedly driven by a 17 year old youngster named Vedant Agarwal collided with a motorbike. The young person was apparently drunk when the incident occurred according to the";

  return (
    <div
      className="featured_fact_check_image_component"
      onClick={handle_redirect}
    >
      <img
        alt={featured_fact_check?.[0]?.title || "YouTurn Fact Check"}
        className="featured_fact_check_image"
        src={image_url || BROKEN_IMAGE}
      />
      <div className="featured_fact_check_thumbnail_overlay_content">
        <div className="featured_fact_check_thumbnail_overlay_title">
          <text
            className={
              language?.name === "tamil"
                ? "featured_article_image_component_title_ta"
                : "featured_fact_check_image_component_title"
            }
          >
            {split_featured_content(featured_fact_check?.[0]?.title)}
          </text>
        </div>
        <div className="exposing_fake_news_first_column_details_wrapper">
          <div className="featured_fact_check_thumbnail_overlay_view_count">
            <LuEye className="featured_fact_check_view_count" />
            <text className="featured_fact_check_view_count">
              {featured_fact_check?.[0]?.views}
            </text>
          </div>
          <text className="featured_fact_check_thumbnail_overlay_name_and_date">
            {`${featured_fact_check?.[0]?.staff_profile?.name} | ${formatted_date}`}
          </text>
          <div>
            <Button className="featured_fact_check_button">
              {t("fact_check_button_text")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedFactCheckImageComponent;
