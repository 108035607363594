import { Col, Row, Typography } from "antd";
import React from "react";

const CustomProfileCard = (props) => {
  const {
    people,
    detail_card_open,
    set_detail_card_open,
    set_person_detail,
    person_detail,
    rowIndex,
    set_open_index,
    empty_click,
    cursor,
    class_name,
  } = props;

  const open_details_card = () => {
    set_person_detail(people);
    set_open_index(rowIndex);
    set_detail_card_open((prevState) => {
      if (people?.id === person_detail?.id) {
        return !prevState;
      } else {
        return true;
      }
    });
  };

  return (
    <div
      className={"profile_card_container"}
      onClick={() =>
        empty_click ? empty_click : open_details_card(people?.id)
      }
      style={{
        cursor: cursor ? cursor : "pointer",
      }}
    >
      <img
        style={{
          cursor: cursor ? cursor : "pointer",
        }}
        src={people?.image}
        alt={people?.name || "Profile"}
        className="profile_img"
      />
      <Row className="team_members_profile">
        <Col className="profile_name_container">
          <Typography className="profile_name">{people?.name}</Typography>
        </Col>

        <Col className="profile_role_container">
          <Typography className="profile_role">{people?.role}</Typography>
        </Col>
      </Row>
    </div>
  );
};

export default CustomProfileCard;
