import { Empty, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import CommonResultsContainer from "./common_results_container/common_results_container";
import { dynamic_clear, useDynamicSelector } from "@src/services/redux";
import TrendingResults from "./trending_results";
import CommonResultsContainerMobileView from "./mobile_view_search_results/common_results_container_mobile_view";
import { useDispatch } from "react-redux";

const Results = (props) => {
  const {
    set_result_type,
    load_more_results,
    set_search_items,
    set_search_fact_check_items,
  } = props;
  const dispatch = useDispatch();
  const [items, set_items] = useState([]);

  const {
    items: search_results_items,
    loading: search_results_items_loading,
    pagination: search_results_items_pagination,
  } = useDynamicSelector("get_search_result_list");

  useEffect(() => {
    if (search_results_items && search_results_items?.length > 0) {
      set_items([...search_results_items]);
    }
  }, [search_results_items]);

  const handle_tab_change = (key) => {
    set_result_type(key);
    dispatch(dynamic_clear("get_search_result_list"));
    if (key === "article") {
      set_search_fact_check_items();
      set_items([]);
    } else if (key === "fact_check") {
      set_search_items([]);
      set_items([]);
    }
  };

  return (
    <div className="search_results_tab_container">
      <Tabs
        defaultActiveKey="fact_check"
        onChange={handle_tab_change}
        className="search_results_tab_contents"
      >
        <Tabs.TabPane tab="Fact Check" key="fact_check">
          <div className="search_results_first_section">
            <div className="tab_pane_inside_container">
              <CommonResultsContainer
                all_stories_list={items}
                handle_page_limit={load_more_results}
                user_contribution_list_loading={search_results_items_loading}
                search_results_items_total_count={
                  search_results_items_pagination?.total_count
                }
              />
              {search_results_items?.length === 0 &&
                !search_results_items_loading && (
                  <div className="search_result_no_data">
                    <Empty description="No data" />
                  </div>
                )}
            </div>
            <div className="search_results_container_trending_results">
              <TrendingResults />
            </div>
          </div>
          <div className="mobile_view_for_first_section">
            <CommonResultsContainerMobileView
              all_stories_list={items}
              handle_page_limit={load_more_results}
              user_contribution_list_loading={search_results_items_loading}
              search_results_items_total_count={
                search_results_items_pagination?.total_count
              }
            />
            {search_results_items?.length === 0 &&
              !search_results_items_loading && (
                <div className="search_result_no_data">
                  <Empty description="No data" />
                </div>
              )}
            <div className="search_results_container_trending_results">
              <TrendingResults />
            </div>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Articles" key="article">
          <div className="search_results_first_section">
            <div className="tab_pane_inside_container">
              <CommonResultsContainer
                all_stories_list={items}
                handle_page_limit={load_more_results}
                user_contribution_list_loading={search_results_items_loading}
                title={""}
                search_results_items_total_count={
                  search_results_items_pagination?.total_count
                }
              />
              {search_results_items?.length === 0 &&
                !search_results_items_loading && (
                  <div className="search_result_no_data">
                    <Empty description="No data" />
                  </div>
                )}
            </div>
            <div className="search_results_container_trending_results">
              <TrendingResults />
            </div>
          </div>
          <div className="mobile_view_for_first_section">
            <CommonResultsContainerMobileView
              all_stories_list={items}
              handle_page_limit={load_more_results}
              user_contribution_list_loading={search_results_items_loading}
              title={""}
              search_results_items_total_count={
                search_results_items_pagination?.total_count
              }
            />
            {search_results_items?.length === 0 &&
              !search_results_items_loading && (
                <div className="search_result_no_data">
                  <Empty description="No data" />
                </div>
              )}
            <div className="search_results_container_trending_results">
              <TrendingResults />
            </div>
          </div>
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default Results;
