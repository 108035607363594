import React, { useEffect, useState } from "react";
import axios from "axios";

const RssFeed = () => {
  const [rssData, setRssData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let url = "https://youturn.in/api/rest/get_rss_feed/ta?res=rss";
    if (window.location.hostname === "en.youturn.in") {
      url = "https://youturn.in/api/rest/get_rss_feed/en?res=rss";
    }
    axios
      .get(url, { headers: { "Content-Type": "application/xml" } })
      .then((response) => {
        const xmlData = response.data;
        setRssData(xmlData);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  // useEffect(() => {
  //   if (rssData) {
  //     downloadXml();
  //   }
  // }, [rssData]);

  const downloadXml = () => {
    if (rssData) {
      const blob = new Blob([rssData], { type: "application/xml" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "rss_feed.xml"; // The file name for download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div style={{ height: "100vh", overflowY: "scroll" }}>
      <pre>
        <code>{rssData}</code>
      </pre>
    </div>
  );
};

export default RssFeed;
