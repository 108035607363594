import React from "react";
import { retrieve_item } from "@src/helpers/cryptos";
import { generate_share_invite_friends } from "@src/helpers/functions";
import { YOUTURN_JOIN_US_LOGO } from "@src/helpers/image_constants";
import { Button, Popover } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@src/route/my_routes";

const JoinUs = () => {
  const { t } = useTranslation();
  const user_id = retrieve_item("user_id");
  const navigate = useNavigate();
  const { name } = retrieve_item("language");
  const url = process.env.REACT_APP_WEB_URL;
  const share_invite_friends = generate_share_invite_friends(url);

  const handle_page_redirect = () => {
    navigate(ROUTES.LOGIN);
  };

  return (
    <div className="join_is_container">
      <div className="join_us_first_column"></div>

      <div className="join_us_second_column">
        {!user_id ? (
          <div className="join_us_second_inner_container">
            <div>
              <text className="join_us_today_text">{t("join_us_today")}</text>
            </div>
            <div>
              <text className="join_us_feature_text">{t("join_feature")}</text>
            </div>
            <div>
              <Button className="join_us_button" onClick={handle_page_redirect}>
                {t("become_a_member")}
              </Button>
            </div>
          </div>
        ) : (
          <>
            <div className="join_us_feature_text_container">
              <text
                className={
                  name === "tamil"
                    ? "join_us_feature_text_ta"
                    : "join_us_feature_text"
                }
              >
                {t("thanks_content")}
              </text>
            </div>

            <div>
              <Popover
                placement="top"
                content={share_invite_friends}
                trigger="hover"
                className="share_pop_over"
              >
                <Button className="join_us_button">
                  {t("invite_friends")}
                </Button>
              </Popover>
            </div>
          </>
        )}
      </div>
      <div className="join_us_third_column">
        <img
          alt={"YouTurn"}
          src={YOUTURN_JOIN_US_LOGO}
          className="youturn_join_us_logo"
        />
      </div>
    </div>
  );
};
export default JoinUs;
