import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { useAPIRequest } from "@src/helpers/hooks";
import {
  YOUTURN_NEW_LOGO,
  YOU_TURN_LOGO_BLUE,
} from "@src/helpers/image_constants";
import { ROUTES } from "@src/route/my_routes";
import { reset_password_query } from "@src/services/graphql/graphql_login";
import { dynamic_clear, useDynamicSelector } from "@src/services/redux";
import { Form, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import LogoImageForHomePage from "../components/login/sign_up_form/logo_image_form_home";

const ResetPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();

  const [password_visible, set_password_visible] = useState(false);
  const [reset_password_toast, set_reset_password_toast] = useState(false);
  const [confirm_password_visible, set_confirm_password_visible] =
    useState(false);
  const [error_message, set_error_message] = useState("");
  const token = location.pathname.replace(/\/reset-password\//, "");

  const {
    status: reset_password_status,
    error: reset_password_error,
    loading: reset_password_loading,
  } = useDynamicSelector("reset_forgot_password");

  let reset_password_request = useAPIRequest(
    "reset_forgot_password",
    reset_password_query
  );

  const reset_password = (values) => {
    reset_password_request(
      {
        new_password: values?.new_password,
        token: token,
      },
      true
    );
  };

  const onFinish = (values) => {
    reset_password(values);
    form.resetFields("");
  };
  const handle_back = () => {
    navigate(ROUTES.HOME);
  };
  const toggle_password_visibility = () => {
    set_password_visible(!password_visible);
  };
  const toggle_Password_visibility = () => {
    set_confirm_password_visible(!confirm_password_visible);
  };

  useEffect(() => {
    if (reset_password_status === "Success") {
      navigate(ROUTES.LOGIN);
      dispatch(dynamic_clear("reset_forgot_password"));
    } else if (reset_password_status === "Failure") {
      if (reset_password_error.status_code === "500") {
        set_reset_password_toast(true);
        set_error_message(reset_password_error?.message);
      } else if (
        reset_password_error.message ||
        reset_password_error.status_code !== "500"
      ) {
        set_reset_password_toast(true);
        set_error_message(reset_password_error?.message);
      }

      dispatch(dynamic_clear("reset_forgot_password"));
    }
    dispatch(dynamic_clear("login"));
  }, [reset_password_status, reset_password_error]);

  useEffect(() => {
    let timeout;
    if (reset_password_toast) {
      timeout = setTimeout(() => {
        set_reset_password_toast(false);
      }, 5000);
    }
    return () => clearTimeout(timeout);
  }, [reset_password_toast]);
  return (
    <div className="login_page_main_container">
      <LogoImageForHomePage />
      <div className="container" id="container">
        <div className="form-container sign-up">
          <Form onFinish={onFinish} layout="vertical">
            <img
              style={{
                borderRadius: "9px",
                zIndex: 1,
                objectFit: "cover",
                height: "80px",
              }}
              src={YOUTURN_NEW_LOGO}
              alt="Profile"
              className="youturn_logo_mobile_view_only"
            />
            <h2 className="sing_in_text">Create Account</h2>
            <div className="view-text">
              <h6 className="sing_in_text">Already have an account?</h6>
              <a
                href="#"
                className="sing_in_text"
                style={{ marginLeft: "5vh" }}
              >
                Sign in
              </a>
            </div>
            <Form.Item
              className="margin-bottom"
              name="name"
              rules={[{ required: true, message: "Name is required" }]}
            >
              <input
                type="text"
                placeholder="Name"
                style={{ width: "300px" }}
              />
            </Form.Item>
            <Form.Item
              className="margin-bottom"
              name="mobile"
              rules={[{ required: true, message: "Mobile number is required" }]}
            >
              <input
                type="number"
                placeholder="Mobile"
                style={{ width: "300px" }}
              />
            </Form.Item>
            <Form.Item
              className="margin-bottom"
              name="email"
              rules={[{ required: true, message: "Email is required" }]}
            >
              <input
                type="email"
                placeholder="Email"
                style={{ width: "300px" }}
              />
            </Form.Item>

            <button type="submit">
              <div class="leap-frog">
                <div class="leap-frog__dot"></div>
                <div class="leap-frog__dot"></div>
                <div class="leap-frog__dot"></div>
              </div>
              "Submit"
            </button>
          </Form>
        </div>
        <div className="form-container sign-in">
          <Form onFinish={onFinish} layout="vertical" form={form}>
            <h1 className="sing_in_text">Reset Password</h1>
            <div className="view-text">
              <h6
                style={{ textDecoration: "underline" }}
                className="not_register_text"
              ></h6>
              <a href="" style={{ marginLeft: "3vh", color: "white" }}></a>
            </div>

            <Form.Item
              style={{
                display: "flex",
                flexDirection: "revert-layer",
                alignItems: "center",
                width: "min-content",
              }}
              className="margin-bottom"
              name="new_password"
              rules={[
                {
                  validator: (_, value) => {
                    if (!value) {
                      return Promise.reject("Password is required");
                    }
                    if (value.length < 8) {
                      return Promise.reject(
                        "Password must be at least 8 characters long"
                      );
                    }
                    if (
                      !/(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+])/.test(value)
                    ) {
                      return Promise.reject(
                        <div>{t("strong_password_rule")}</div>
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <div style={{ position: "relative" }}>
                <input
                  type={password_visible ? "text" : "password"}
                  placeholder="Password"
                  style={{ width: "300px" }}
                />
                {password_visible ? (
                  <EyeInvisibleOutlined
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                    onClick={toggle_password_visibility}
                  />
                ) : (
                  <EyeOutlined
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                    onClick={toggle_password_visibility}
                  />
                )}
              </div>
            </Form.Item>

            <Form.Item
              className="margin-bottom"
              name="confirm_password"
              rules={[
                {
                  required: true,
                  message: `${t("confirm_password")} is required`,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("new_password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("The two passwords do not match")
                    );
                  },
                }),
              ]}
            >
              <div style={{ position: "relative" }}>
                <input
                  type={confirm_password_visible ? "text" : "password"}
                  placeholder="Confirm Password"
                  style={{ width: "300px" }}
                />
                {confirm_password_visible ? (
                  <EyeInvisibleOutlined
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                    onClick={toggle_Password_visibility}
                  />
                ) : (
                  <EyeOutlined
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                    onClick={toggle_Password_visibility}
                  />
                )}
              </div>
            </Form.Item>
            <Tooltip open={reset_password_toast} title={error_message}>
              <button type="submit">
                {reset_password_loading ? (
                  <div class="leap-frog">
                    <div class="leap-frog__dot"></div>
                    <div class="leap-frog__dot"></div>
                    <div class="leap-frog__dot"></div>
                  </div>
                ) : (
                  "Reset"
                )}
              </button>
            </Tooltip>
          </Form>
        </div>
        <div className="toggle-container">
          <div className="toggle">
            <div className="toggle-panel toggle-left">
              <div
                style={{
                  marginTop: "70px",
                }}
              >
                <img
                  style={{
                    borderRadius: "9px",
                    zIndex: 1,
                    objectFit: "cover",
                    height: "80px",
                  }}
                  src={YOUTURN_NEW_LOGO}
                  alt="Youturn_logo"
                />
                <h1>Welcome Back!</h1>
                <p>Unlock Your News Universe! Register Today.</p>
              </div>
            </div>
            <div className="toggle-panel toggle-right toggle-panel-sign-in">
              <div
                style={{
                  marginTop: "70px",
                }}
              >
                <img
                  style={{
                    borderRadius: "9px",
                    zIndex: 1,
                    objectFit: "cover",
                    height: "80px",
                  }}
                  src={YOUTURN_NEW_LOGO}
                  alt="Youturn_logo"
                />
                <h1>Welcome</h1>
                <p>Your News Awaits. Sign In Here!</p>
                <div
                  style={{
                    height: "8vh",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "center",
                  }}
                  className="sing_up_button_div"
                >
                  <button
                    className="hidden"
                    id="register"
                    onClick={handle_back}
                  >
                    Sign in
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
