import {
  POLITICS_ICON,
  MEDICINE_ICON,
  MEDIA_RUMOR_ICON,
  NEWS_CHECK_ICON,
} from "@src/helpers/image_constants";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export default function WhatWeCheck() {
  const { t } = useTranslation();
  const [current_index, set_current_index] = useState(null);
  const items = [
    {
      img: POLITICS_ICON,
      title: t("political_fact_checking"),
      content:
        "Scrutinizing claims made by political parties, leaders and other persons in positions of authority across the political spectrum.",
    },
    {
      img: MEDICINE_ICON,
      title: t("health_Medicine"),
      content:
        "Fact check for evidence-based medicine & critiques misinformation in public health policies",
    },
    {
      img: MEDIA_RUMOR_ICON,
      title: t("social_media_rumours"),
      content:
        "Debunking the misinformation and disinformation that circulates on social media.",
    },
    {
      img: NEWS_CHECK_ICON,
      title: t("mainstream_media"),
      content:
        "Examining inaccurate or misleading information disseminated by mainstream media, either inadvertently or with an objective of shaping public opinion",
    },
  ];

  const handle_click = (index) => {
    set_current_index(current_index === index ? null : index);
  };
  return (
    <div className="">
      <p className="policies_heading">{t("what_do_we_check")}</p>
      <div className="what_we_check_div">
        {items.map((list, index) => {
          return (
            <div
              key={index}
              className="what_we_check_items"
              onClick={() => handle_click(index)}
            >
              <img
                src={list.img}
                alt="YouTurn"
                className="what_we_check_img"
                style={{ display: current_index === index ? "none" : "block" }}
              />
              <p className="what_we_check_text">{list.title}</p>
              <p
                className="what_we_check_content"
                style={{ display: current_index === index ? "block" : "none" }}
              >
                {list.content}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
