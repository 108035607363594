import {
  AWARD_IMAGE,
  BROKEN_IMAGE,
  STAR_IMAGE,
  WHITE_STAR,
} from "@src/helpers/image_constants";
import React, { useEffect } from "react";
import AwardsList from "./awards/awards_list";
import { useTranslation } from "react-i18next";
import { useAPIRequest } from "@src/helpers/hooks";
import { useDynamicSelector } from "@src/services/redux";
import { get_award_query } from "@src/services/graphql/graphql_home";
import moment from "moment";
import { split_tamil_english_words } from "@src/helpers/functions";

function AwardsAndRecognition() {
  const { t } = useTranslation();
  let award_list_request = useAPIRequest("get_award_list", get_award_query);

  const { items: award_list, loading: award_list_loading } =
    useDynamicSelector("get_award_list");

  useEffect(() => {
    award_list_request({}, true);
  }, []);
  const handle_redirect = () => {
    window.open(award_list?.[0]?.perma_link);
  };
  return (
    <div className="award_list_container">
      <div className="award_list_div">
        <div className="award_list_one">
          <p className="medical_expert_heading">
            {t("awards_and_recognition")}
          </p>
          <p className="award_list_one_description">{`${
            award_list?.[0]?.subtitle
          } | ${moment(award_list?.[0]?.created_date_time).format(
            "MMMM YYYY"
          )}`}</p>
          <p className="award_list_one_content">
            {award_list?.[0]?.description}
          </p>
        </div>
        <div className="award_list_two" onClick={handle_redirect}>
          {/* <img src={award_list?.[0]?.thumbnail_img} alt="" className="award_list_two_img" style={{ cursor: "pointer" }} /> */}
          <div className="home_awards_card_image_div" onClick={handle_redirect}>
            <img
              alt={"awards"}
              src={award_list?.[0]?.thumbnail_img || BROKEN_IMAGE}
              className="home_awards_card_image"
            />
            <div
              className="home_awards_card_image_overlay_content"
              style={{ backgroundColor: "#00adee" }}
            >
              <img
                alt={"star image"}
                className="award_card_content_image"
                visible={false}
                preview={false}
                src={WHITE_STAR}
              />
              <h6
                className="home_awards_card_image_overlay_text"
                style={{ color: "white" }}
              >
                {
                  "BBC Tamil appreciated our efforts against Fake News in this 2018 Interview"
                }
                {/* {split_tamil_english_words(award_list?.[0]?.title, "", 5)} */}
              </h6>
            </div>
          </div>
        </div>
      </div>

      <AwardsList />
    </div>
  );
}

export default AwardsAndRecognition;
